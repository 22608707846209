import {Faculty} from './faculty.model';
import * as _ from 'lodash';
import {DateTime} from 'luxon';

export interface Course {
    // meta-data
    created: Date;
    createdBy?: string;
    updated: Date;
    updatedBy?: string;

    // data
    id?: string;
    courseName: string;
    courseNumber: string;
    adminEmails: string[];
    courseLogo?: string;
    description: string;
    startDate?: Date;
    endDate?: Date;
    semester?: 'spring' | 'summer' | 'fall' | 'winter';
    submissionDeadline?: Date;
    files?: File[];
    tags?: string[];
    studentEducationLevel?: string;
    studentExperience?: string;
    studentCount?: number;
    studentClassSize?: number;
    studentTeamSize?: number;
    studentHourPerCourse?: number;
    studentCapabilities?: string;
    studentDeliverables?: string;
    preferredCompanyName?: string;
    preferredCompanyType?: string;
    preferredCompanyIndustry?: string;
    preferredCompanyLocation?: string;
    termsAndConditionsForCompany?: string;
    additionalInformation?: string;
    facultyDetails: Faculty;
    status: string;
    isLocked: boolean;
    lockedBy: string;
    lockedOn: Date;
}

interface CourseElasticInterface {
    type?: string;

    created?: string;
    created_by?: any;
    updated?: string;
    updated_by?: any;
    id?: string;
    organization_id?: string;
    courseLogo?: string;

    course_name?: string;
    course_number?: string;
    adminEmails?: string[];
    description?: string;
    start_date?: string;
    end_date?: string;
    semester?: 'spring' | 'summer' | 'fall' | 'winter';
    tags?: string[];
    additional_information?: string;
    files?: string[];

    faculty_name?: string;
    faculty_position?: string;
    faculty_campus?: string;
    faculty_college?: string;
    faculty_department?: string;
    faculty_university?: string;
    faculty_university_logo?: string;

    student_education_level?: string;
    student_experience?: string;
    student_count?: number;
    student_hour_per_course?: number;
    student_class_size?: number;
    student_team_size?: number;
    student_capabilities?: string;
    student_deliverables?: string;

    preferred_company_industry?: string;
    preferred_company_location?: string;
    preferred_company_type?: string;
    preferred_project_length?: number;

    status?: string;
    isLocked?: boolean;
    lockedBy?: string;
    lockedOn?: Date;
}

export class CourseElasticDocument implements CourseElasticInterface {
    type?: string = undefined;

    created?: string = undefined;
    created_by?: any = undefined;
    updated?: string = undefined;
    updated_by?: any = undefined;
    id?: string = undefined;
    organization_id?: string = undefined;
    course_logo?: string = undefined;

    course_name?: string = undefined;
    course_number?: string = undefined;
    admin_emails?: string[] = undefined;
    description?: string = undefined;
    start_date?: string = undefined;
    end_date?: string = undefined;
    semester?: 'spring' | 'summer' | 'fall' | 'winter' = undefined;
    tags?: string[] = undefined;
    additional_information?: string = undefined;
    files?: string[] = undefined;

    faculty_name: string = undefined;
    faculty_position: string = undefined;
    faculty_campus: string = undefined;
    faculty_college: string = undefined;
    faculty_department: string = undefined;
    faculty_university: string = undefined;
    faculty_university_logo: string = undefined;

    student_education_level?: string = undefined;
    student_experience?: string = undefined;
    student_count?: number = undefined;
    student_hour_per_course?: number = undefined;
    student_class_size?: number = undefined;
    student_team_size?: number = undefined;
    student_capabilities?: string = undefined;
    student_deliverables?: string = undefined;

    preferred_company_industry: string = undefined;
    preferred_company_location: string = undefined;
    preferred_company_type: string = undefined;
    preferred_project_length: number = undefined;

    status?: string = undefined;

    is_locked?: boolean = undefined;
    locked_by?: string = undefined;
    locked_on?: Date = undefined;

    constructor(source: any = {}) {
        _.forEach(Object.getOwnPropertyNames(this), (propertyName) => {
            if (propertyName in source && !_.isUndefined(source[propertyName])) {
                this[propertyName] = source[propertyName];
            } else if (_.isUndefined(this[propertyName])) {
                delete this[propertyName];
            }
        });
    }

    toCourse(): Course {
        const course = {};

        _.set(course, 'created', DateTime.fromISO(this.created).toJSDate());
        _.set(course, 'createdBy', this.created_by ? this.created_by.id : null);

        _.set(course, 'updated', DateTime.fromISO(this.updated).toJSDate());
        _.set(course, 'updatedBy', this.updated_by ? this.updated_by.id : null);

        _.set(course, 'id', this.id);
        _.set(course, 'organizationId', this.organization_id);
        _.set(course, 'courseName', this.course_name);
        _.set(course, 'courseNumber', this.course_number);
        _.set(course, 'adminEmails', this.admin_emails);
        _.set(course, 'courseLogo', this.course_logo);
        _.set(course, 'description', this.description);
        _.set(course, 'startDate', this.start_date ? DateTime.fromISO(this.start_date).toJSDate() : null);
        _.set(course, 'endDate', this.end_date ? DateTime.fromISO(this.end_date).toJSDate() : null);
        _.set(course, 'semester', this.semester);
        _.set(course, 'tags', this.tags);
        _.set(course, 'additionalInformation', this.additional_information);
        _.set(course, 'facultyDetails.name', this.faculty_name);
        _.set(course, 'facultyDetails.position', this.faculty_position);
        _.set(course, 'facultyDetails.campus', this.faculty_campus);
        _.set(course, 'facultyDetails.college', this.faculty_college);
        _.set(course, 'facultyDetails.department', this.faculty_department);
        _.set(course, 'facultyDetails.university', this.faculty_university);
        _.set(course, 'facultyDetails.universityLogo', this.faculty_university_logo);
        _.set(course, 'studentEducationLevel', this.student_education_level);
        _.set(course, 'studentExperience', this.student_experience);
        _.set(course, 'studentCount', this.student_count);
        _.set(course, 'studentHourPerCourse', this.student_hour_per_course);
        _.set(course, 'studentClassSize', this.student_class_size);
        _.set(course, 'studentTeamSize', this.student_team_size);
        _.set(course, 'studentCapabilities', this.student_capabilities);
        _.set(course, 'studentDeliverables', this.student_deliverables);
        _.set(course, 'preferredCompanyIndustry', this.preferred_company_industry);
        _.set(course, 'preferredCompanyLocation', this.preferred_company_location);
        _.set(course, 'preferredCompanyType', this.preferred_company_type);
        _.set(course, 'preferredProjectLength', this.preferred_project_length);
        _.set(course, 'status', this.status);
        _.set(course, 'isLocked', this.is_locked);
        _.set(course, 'lockedBy', this.locked_by);
        _.set(course, 'lockedOn', this.locked_on);

        return course as Course;
    }
}
