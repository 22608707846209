<div simplebar>
    <app-header *ngIf="!_router.url.startsWith('/auth')"></app-header>
    <button mat-icon-button
            id="support-button"
            color="primary"
            matTooltip="Support"
            (click)="openReportDialog()">
        <div class="p-8">
            <mat-icon class="scale-150 hover:scale-[2]">help</mat-icon>
        </div>
    </button>
    <router-outlet></router-outlet>
</div>
