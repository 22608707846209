import {Component, OnDestroy, OnInit} from '@angular/core';
import {LoginDialogComponent} from '../../auth/dialogs/login-dialog/login-dialog.component';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {AuthService} from '../../services/auth.service';
import {Router} from '@angular/router';
import {take, takeUntil} from 'rxjs/operators';
import {LoggingService} from '../../services/logging.service';
import {Subject} from 'rxjs';
import {ProjectService} from '../../services/project.service';
import {CourseService} from '../../services/course.service';
import {OrganizationService} from '../../services/organization.service';

@Component({
    selector: 'app-home-page',
    templateUrl: './home-page.component.html',
    styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit, OnDestroy {
    currentUser: any;
    isLoggedIn: boolean;
    loading: boolean;
    projectsLoading: boolean;
    coursesLoading: boolean;
    projects: any[];
    courses: any[];
    role: string;
    organizationName: string;
    organization: any;
    private readonly unsubscribeAll: Subject<void>;

    constructor(
        public _matDialog: MatDialog,
        private _authService: AuthService,
        private _router: Router,
        private _loggingService: LoggingService,
        private _projectService: ProjectService,
        private _courseService: CourseService,
        private _organizationService: OrganizationService
    ) {
        this.isLoggedIn = false;
        this.unsubscribeAll = new Subject();
    }

    async ngOnInit(): Promise<void> {
        try {
            this.loading = true;
            this.projectsLoading = true;
            this.coursesLoading = true;
            this._authService.currentUser().pipe(takeUntil(this.unsubscribeAll)).subscribe(async (user) => {
                try {
                    if (user) {
                        this.currentUser = user;
                        const authDoc = await this._authService.getUserAuthDoc();
                        this.role = authDoc.role;
                        this.isLoggedIn = true;
                    }
                } catch (e) {
                    this._loggingService.logError(e, '/src/app/components/home-page/home-page.component.ts', 'ngOnInit', 40);
                }
            });

            this.organization = await this._organizationService.getOrganization();
            this.organizationName = this.organization ? this.organization.name : null;
            const targetOrganizationIds = ['all_organizations'];
            if (this.organization && this.organization.id) {
                targetOrganizationIds.push(this.organization.id);
            }

            const searchProjects = await this._projectService.searchProjects('', {
                    organizationId: (this.isLoggedIn && this.role === 'employer') ? this.organization.id : null,
                    status: ['published'],
                    targetOrganizationIds: (this.isLoggedIn && this.role === 'employer') ? null : targetOrganizationIds
                },
                'created', 'desc', 4, 0);

            this.projects = searchProjects.projects;
            this.projectsLoading = false;

            const searchCourses = await this._courseService.searchCourses('',
                {organizationId: (this.isLoggedIn && this.role !== 'employer') ? this.organization.id : null, status: ['published']},
                'created', 'desc', 4, 0);

            this.courses = searchCourses.courses;
            this.coursesLoading = false;

            this.loading = false;
        } catch (e) {
            this.loading = false;
            this.projectsLoading = false;
            this.coursesLoading = false;
            this._loggingService.logError(e, '/src/app/components/home-page/home-page.component.ts', 'ngOnInit', 45);
        }
    }

    openLoginDialog(redirectRoute: string = '/'): void {
        try {
            const dialogRef = this._matDialog.open(LoginDialogComponent, {
                maxWidth: '800px',
                minWidth: '400px',
                maxHeight: '800px',
                minHeight: '400px',
                disableClose: false
            });
            dialogRef.afterClosed().pipe(takeUntil(this.unsubscribeAll)).subscribe(v => {
                try {
                    if (v) {
                        this._router.navigate([redirectRoute]).catch(e => {
                            this._loggingService.logError(e, '/src/app/components/home-page/home-page.component.ts', 'openLoginDialog', 60);
                        });
                    }
                } catch (e) {
                    this._loggingService.logError(e, '/src/app/components/home-page/home-page.component.ts', 'openLoginDialog', 64);
                }
            });
        } catch (e) {
            this._loggingService.logError(e, '/src/app/components/home-page/home-page.component.ts', 'openLoginDialog', 135);
        }
    }

    onProjectButtonClick(): void {
        if (this.isLoggedIn) {
            this._router.navigateByUrl('/app/projects/create').catch(e => {
                this._loggingService.logError(e, '/src/app/components/home-page/home-page.component.ts', 'onProjectButtonClick', 111);
            });
        } else {
            // this.openLoginDialog('/app/projects/create');
            // Set the current path with query params if present
            this._authService.setRedirectOnLogin('/app/projects/create');

            this._router.navigateByUrl('/auth').catch(e => {
                this._loggingService.logError(e, '/src/app/components/home-page/home-page.component.ts', 'onProjectButtonClick', 116);
            });
        }
    }

    onCourseButtonClick(): void {
        if (this.isLoggedIn) {
            this._router.navigateByUrl('/app/courses/create').catch(e => {
                this._loggingService.logError(e, '/src/app/components/home-page/home-page.component.ts', 'onCourseButtonClick', 124);
            });
        } else {
            // this.openLoginDialog('app/courses/create');
            // Set the current path with query params if present
            this._authService.setRedirectOnLogin('/app/courses/create');

            this._router.navigateByUrl('/auth').catch(e => {
                this._loggingService.logError(e, '/src/app/components/home-page/home-page.component.ts', 'onCourseButtonClick', 129);
            });
        }
    }

    routeToCourse(courseId): void {
        this._router.navigateByUrl(`/app/courses/details/${courseId}`);

    }

    routeToProject(projectId): void {
        this._router.navigateByUrl(`/app/projects/details/${projectId}`);

    }

    ngOnDestroy(): void {
        this.unsubscribeAll.next();
        this.unsubscribeAll.complete();
    }

}
