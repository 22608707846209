import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginGuard} from './guards/login.guard';
import {AuthGuard} from './guards/auth.guard';

const routes: Routes = [
    {
        path: 'auth',
        canActivate: [AuthGuard],
        loadChildren: () => import('./auth/auth.module').then(module => module.AuthModule),
    },
    {
        path: 'app',
        loadChildren: () => import('./components/components.module').then(module => module.ComponentsModule),
    },
    {
        path: 'student',
        canActivate: [LoginGuard],
        loadChildren: () => import('./components/student/student.module').then(module => module.StudentModule),
    },
    {
        path: '**',
        redirectTo: 'app'
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
