import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {DomSanitizer} from '@angular/platform-browser';
import {MatIconRegistry} from '@angular/material/icon';

import {SplashScreenService} from './services/splash-screen.service';
import {icons8FileNames} from '../assets/images/icons/icons8/fileNames';
import {SnackBarService} from './services/snack-bar.service';
import {takeUntil} from 'rxjs/operators';
import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';
import {Subject} from 'rxjs';
import {AuthReportDialogComponent} from './auth/dialogs/auth-report-dialog/auth-report-dialog.component';
import {MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig} from '@angular/material/legacy-dialog';
import {LoggingService} from './services/logging.service';
import {ApplicationUpdateService} from './services/application-update.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy, OnInit{
    title = 'projects-portal';
    private unsubscribeAll: Subject<void>;

    constructor(
        private _applicationUpdateService: ApplicationUpdateService,
        public _router: Router,
        public _dialog: MatDialog,
        private _splashScreenService: SplashScreenService,
        private _matIconRegistry: MatIconRegistry,
        private _domSanitizer: DomSanitizer,
        private _snackBarService: SnackBarService,
        private _snackBar: MatSnackBar,
        private _loggingService: LoggingService
    ){
        this.unsubscribeAll = new Subject<any>();

        // Initialize the splash screen directly
        this._splashScreenService.init();

        // Start the application update service
        this._applicationUpdateService.init();

        // Initialize custom icons8 icons in the assets folder
        for (const icon of icons8FileNames) {
            const iconName = icon.replace('icons8-', '').replace('.svg', '');
            this._matIconRegistry.addSvgIconInNamespace('icons8', iconName,
                this._domSanitizer.bypassSecurityTrustResourceUrl(`../assets/images/icons/icons8/${icon}`));
        }

    }

    ngOnInit(): void {
        this._snackBarService.snackBarSubject
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe(snack => {
                if (snack) {
                    this._snackBar.open(snack.message, snack.action, snack.config);
                }
                else {
                    this._snackBar.dismiss();
                }
            });
    }

    ngOnDestroy(): void {
        this.unsubscribeAll.next();
        this.unsubscribeAll.complete();
    }

    openReportDialog(): void {
        try {
            const dialogRef = this._dialog.open(AuthReportDialogComponent, {
                width: '800px',
                maxWidth: '90%',
                maxHeight: '90%',
                data: {
                    formType: 'question'
                }
            } as MatDialogConfig);
        } catch (e) {
            this._loggingService.logError(e, '/src/app/components/dashboard/dashboard.component.ts',
                'openReportDialog', 243);
            this._snackBarService.open('Failed to open support form.');
        }
    }
}

