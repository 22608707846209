import {NgModule} from '@angular/core';
import {AuthReportDialogComponent} from './auth-report-dialog.component';
import {CommonModule} from '@angular/common';
import {SharedModule} from '../../../shared/shared.module';


@NgModule({
    declarations: [
        AuthReportDialogComponent
    ],
    imports: [
        CommonModule,
        SharedModule
    ]
})
export class AuthReportDialogModule {
}
