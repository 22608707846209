<div class="auth-container" fxLayout="row" fxLayoutAlign="space-around stretch"
     fxLayout.lt-sm="column" fxLayoutAlign.lt-sm="start stretch">
    <div class="signup-container" fxLayout="column" fxLayoutAlign="center center" fxFlex="50"
         fxLayoutAlign.lt-sm="start stretch">

        <div fxLayout="column" fxLayoutAlign="start stretch">
            <div fxLayout="row" fxLayoutAlign="space-between center"
                 fxLayout.lt-sm="column" fxLayoutAlign.lt-sm="center center">
                <div class="logo-container" fxFlex="80">
                    <a class="cursor-pointer" [routerLink]="['/', 'app']">
                        <img *ngIf="!isDarkMode" class="logo" src="assets/images/logos/student-projects-color-logo-color-text.svg"
                             alt="Student Opportunity Center Logo"/>
                        <img *ngIf="isDarkMode" class="logo" src="assets/images/logos/student-projects-white-logo-white-text.svg"
                             alt="Student Opportunity Center Logo"/>
                    </a>
                </div>
                <div class="my-16" fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap="4px" fxFlex="20">
                    <span>Have an account?</span>
                    <a class="cursor-pointer" (click)="openLoginDialog()">Sign In</a>
                </div>
            </div>
        </div>

        <mat-progress-bar [mode]="'indeterminate'" *ngIf="registerLoading"></mat-progress-bar>

        <app-register (signupSuccessful)="handleSignup($event)"
                      (registerError)="showChip = false"
                      (registerLoading)="registerLoading = $event">
        </app-register>
    </div>
    <div class="image-container" fxFlex="50" fxHide.lt-md>
        <img width="100%" height="100%" alt="Image"
            src="https://firebasestorage.googleapis.com/v0/b/soc-firebase-dev.appspot.com/o/projectsPortal%2FDeco.jpg?alt=media&token=855b41b9-1057-4428-84aa-5d2d1be1ef9e">
    </div>
</div>
