import {Component, OnInit} from '@angular/core';
import {ThemeService} from '../../../services/theme.service';
import {MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';

@Component({
    selector: 'app-register-dialog',
    templateUrl: './register-dialog.component.html',
    styleUrls: ['./register-dialog.component.scss']
})
export class RegisterDialogComponent implements OnInit {
    isDarkMode: boolean | undefined;

    constructor(
        private _themeService: ThemeService,
        public _dialogRef: MatDialogRef<RegisterDialogComponent>,
    ) {
        this._themeService.isDarkMode().subscribe(v => this.isDarkMode = v);
    }

    ngOnInit(): void {
    }

    handleRegisterDialog(signupStatus: boolean): void{
        if (signupStatus) {
            this._dialogRef.close(true);
        }
    }

}
