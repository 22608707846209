import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
// Prevents logged in users from accessing Auth page and its children
export class AuthGuard implements CanActivate {

    constructor(
        private _fireAuth: AngularFireAuth,
        private _router: Router
    ) {
    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return this.isLoggedIn(route, state);
    }

    async isLoggedIn(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<boolean> {
        const login = route.queryParamMap.get('login');
        const token = route.queryParamMap.get('token');

        // If there is no login token sent in the url, then login incoming user token
        if (login && token) {
            return true;
        }

        const user = await this._fireAuth.user.pipe(take(1)).toPromise();
        // If user logged in redirect to app page
        if (user) {
            return this._router.navigateByUrl('/app/dashboard', {replaceUrl: true});
        }
        return true;
    }
}
