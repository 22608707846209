import {AfterViewInit, Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {Subject} from 'rxjs';
import {take, takeUntil} from 'rxjs/operators';

import {AuthService} from '../services/auth.service';
import {ChipService} from '../services/chip.service';
import {ThemeService} from '../services/theme.service';
import {LoggingService} from '../services/logging.service';

import {LoginDialogComponent} from './dialogs/login-dialog/login-dialog.component';

@Component({
    selector: 'app-auth',
    templateUrl: './auth.component.html',
    styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit, AfterViewInit {
    isDarkMode: boolean;
    private readonly unsubscribeAll: Subject<void>;
    showChip: boolean;
    registerLoading: boolean;
    loginLoading: boolean;

    constructor(
        private _router: Router,
        private _activatedRoute: ActivatedRoute,
        private _themeService: ThemeService,
        private _loggingService: LoggingService,
        private _authService: AuthService,
        private _chipService: ChipService,
        public _matDialog: MatDialog,
    ) {
        this.unsubscribeAll = new Subject();
    }

    async ngOnInit(): Promise<void> {
        await this._themeService.isDarkMode().pipe(takeUntil(this.unsubscribeAll)).subscribe(v => {
            try {
                this.isDarkMode = v;
            } catch (e) {
                this._loggingService.logError(e, '/src/app/auth/auth.component.ts', 'ngOnInit', 43);
            }
        });
    }

    async ngAfterViewInit(): Promise<void> {
        try {
            const queryParams = await this._activatedRoute.queryParams.pipe(take(1)).toPromise();
            // Get the value of the query parameter
            const login = queryParams['login'];
            // Check if the query parameter for login exists and is not false
            if (login !== undefined && login.toLowerCase() !== 'null' && login.toLowerCase() !== 'false') {
                // If the value for login resembles a path, then use it as a redirect path
                if (login.toLowerCase().startsWith('/app/') || login.toLowerCase().startsWith('/student/')) {
                    await this.openLoginDialog(login.toLowerCase());
                }
                else {
                    await this.openLoginDialog();
                }
            }
        } catch (e) {
            this._loggingService.logError(e, '/src/app/auth/auth.component.ts', 'ngAfterViewInit', 64);
        }

    }

    async handleSignup(signupStatus: boolean): Promise<void> {
        try{
            if (signupStatus) {
                // Redirect to user back to original link if the user was redirected to login
                // if (this._authService.redirectOnLogin) {
                //     const redirectUrl = this._authService.redirectOnLogin;
                //     this._authService.setRedirectOnLogin(null);
                //     await this._router.navigateByUrl(redirectUrl);
                // } else {
                //     await this._router.navigate(['/']);
                // }
            }
        } catch (e) {
            this._loggingService.logError(e, '/src/app/auth/auth.component.ts', 'handleSignup', 55);
        }
    }

    async handleLogin(loginStatus: boolean): Promise<void> {
        try {
            if (loginStatus) {
                // Redirect to user back to original link if the user was redirected to login
                if (this._authService.redirectOnLogin) {
                    const redirectUrl = this._authService.redirectOnLogin;
                    this._authService.setRedirectOnLogin(null);
                    await this._router.navigateByUrl(redirectUrl);
                } else {
                    await this._router.navigate(['/']);
                }
            }
        } catch (e) {
            this._loggingService.logError(e, '/src/app/auth/auth.component.ts', 'handleLogin', 72);
        }
    }

    async openLoginDialog(redirectRoute: string = this._authService.redirectOnLogin): Promise<void> {
        try {
            const dialogRef = this._matDialog.open(LoginDialogComponent, {
                maxWidth: '800px',
                minWidth: '400px',
                maxHeight: '800px',
                minHeight: '400px',
                disableClose: true
            });
            dialogRef.afterClosed().pipe(takeUntil(this.unsubscribeAll)).subscribe(async v => {
                try {
                    if (v) {
                        const authDoc = await this._authService.getUserAuthDoc();
                        if (authDoc.role === 'student') {
                            this._router.navigate([redirectRoute ? redirectRoute : '/student/dashboard'])
                                .catch(e => {
                                    this._loggingService.logError(e,
                                        '/src/app/auth/auth.component.ts',
                                        'openLoginDialog', 97);
                                });
                        }
                        else {
                            const settingsDoc = await this._authService.getUserSettingsDoc();

                            if (settingsDoc.firstProjectCreated || settingsDoc.firstCourseCreated) {
                                this._router.navigate([redirectRoute ? redirectRoute : '/app/dashboard'])
                                    .catch(e => {
                                        this._loggingService.logError(e,
                                            '/src/app/auth/auth.component.ts',
                                            'openLoginDialog', 108);
                                    });
                            }
                            else {
                                if (authDoc.role === 'employer') {
                                    this._router.navigate(['/app/projects/create']).catch(e => {
                                        this._loggingService.logError(e,
                                            '/src/app/auth/auth.component.ts',
                                            'openLoginDialog', 116);
                                    });
                                }
                                else {
                                    this._router.navigate(['/app/courses/create']).catch(e => {
                                        this._loggingService.logError(e,
                                            '/src/app/auth/auth.component.ts',
                                            'openLoginDialog', 123);
                                    });
                                }
                            }
                        }
                    }
                } catch (e) {
                    this._loggingService.logError(e, '/src/app/auth/auth.component.ts', 'openLoginDialog', 130);
                }
            });
        } catch (e) {
            this._loggingService.logError(e, '/src/app/auth/auth.component.ts', 'openLoginDialog', 134);
        }
    }
}
