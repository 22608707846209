import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ChipService {
    // Whether or not a flash message should be displayed.
    // Flash messages will show the chip once on load.
    private _hasFlash: boolean;

    private readonly _chip: BehaviorSubject<Chip>;

    constructor() {
        this._chip = new BehaviorSubject({
            chipCode: -1,
            chipParams: {}
        } as Chip);
        this._hasFlash = false;
    }

    get chip(): BehaviorSubject<Chip> {
        return this._chip;
    }

    /**
     * If the service currently has a flash message, return true.
     * Internally set the flash message to false for future gets.
     */
    get flashMessage(): boolean {
        const hasFlash = this._hasFlash;
        if (this._hasFlash) {
            this._hasFlash = false;
        }
        return hasFlash;
    }

    setFlashMessage(): void {
        this._hasFlash = true;
    }

    /**
     * @param chipCode - See html template for the different error codes
     * @param chipParams - Additional parameters to include in the template
     */
    setChip(chipCode: any, chipParams?: any): void {
        const chip: Chip = {
            chipCode: chipCode,
            chipParams: chipParams ? chipParams : {}
        };
        this._chip.next(chip);
    }
}

export interface Chip {
    chipCode: any;
    chipParams?: any;
}
