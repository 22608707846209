import { Component, OnInit, ChangeDetectorRef, AfterContentChecked } from '@angular/core';
import {ThemeService} from '../../../services/theme.service';
import { Router, RouterStateSnapshot } from '@angular/router';
import {MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {AuthService} from '../../../services/auth.service';

@Component({
    selector: 'app-login-dialog',
    templateUrl: './login-dialog.component.html',
    styleUrls: ['./login-dialog.component.scss']
})
export class LoginDialogComponent implements OnInit, AfterContentChecked {
    isDarkMode: boolean;
    showChip: boolean;
    loginLoading: boolean;
    tokenLogin: boolean;

    constructor(
        private _themeService: ThemeService,
        private _router: Router,
        public _dialogRef: MatDialogRef<LoginDialogComponent>,
        private _authService: AuthService,
        private _cdref: ChangeDetectorRef
    ) {
        this.tokenLogin = false;
        this._themeService.isDarkMode()
            .subscribe(v => {
                this.isDarkMode = v;
            });
    }

    ngOnInit(): void {
        const snapshot: RouterStateSnapshot = this._router.routerState.snapshot;
        const customToken = snapshot.root.queryParamMap.get('token');

        if (customToken) {
            this.tokenLogin = true;
        }
    }

    ngAfterContentChecked(): void {
        this._cdref.detectChanges();
    }

    onNoClick(): void {
        this._dialogRef.close(false);
    }

    handleLoginDialog(loginStatus: boolean): void {
        if (loginStatus) {
            this._dialogRef.close(true);
        }
    }

}
