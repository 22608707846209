export const environment = {
    production: true,
    stage: false,
    dev: false,
    hmr: false,
    logging: true,
    stackDriverErrorReportingKey: 'AIzaSyC2gTCgXlsQM5sszhVyN9IA4HpB73MA2yE',
    version: 'prod',
    firebaseConfig: {
        apiKey: 'AIzaSyBf3JXwVeg-SpX9gVOTd5bBn0mgXXEHW-o',
        authDomain: 'soc-firebase.firebaseapp.com',
        databaseURL: 'https://soc-firebase.firebaseio.com',
        projectId: 'soc-firebase',
        storageBucket: 'soc-firebase.appspot.com',
        messagingSenderId: '1082622239330'
    },
    appBaseUrl: 'https://studentprojects.peoplegrove.com',
    apiServiceUrl: 'https://api.studentopportunitycenter.com',
    searchServiceBaseUrl: 'https://soc-search-service.uc.r.appspot.com',
    analyticsServiceBaseUrl: 'https://soc-analytics-service.uc.r.appspot.com',
    userServiceBaseUrl: 'https://user-dot-soc-firebase.uc.r.appspot.com',
    functionsBaseUrl: 'https://us-central1-soc-firebase.cloudfunctions.net',
    storageBaseUrl: 'https://firebasestorage.googleapis.com/v0/b/soc-firebase.appspot.com',
    projectPortalServiceBaseUrl: 'https://projects-portal-dot-soc-firebase.uc.r.appspot.com',
    chatServiceBaseUrl: 'https://chat-dot-soc-firebase.appspot.com',
    googleRecaptchaSecretKey: '6LfnI-MUAAAAANCvywsJ90mnIdT_s9zlPOJiOaLz',
    elBaseUrl: 'https://app.studentopportunitycenter.com',
};
