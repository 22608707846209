import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ThemeService} from 'src/app/services/theme.service';
import {AuthService} from '../../services/auth.service';
import {Subject} from 'rxjs';
import {Router} from '@angular/router';
import {LoginDialogComponent} from '../../auth/dialogs/login-dialog/login-dialog.component';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {RegisterDialogComponent} from '../../auth/dialogs/register-dialog/register-dialog.component';
import {takeUntil} from 'rxjs/operators';
import {LoggingService} from '../../services/logging.service';
import {MatLegacyMenuTrigger as MatMenuTrigger} from '@angular/material/legacy-menu';
import { OrganizationService } from 'src/app/services/organization.service';
import {environment} from '../../../environments/environment';
import * as _ from 'lodash';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
    isDarkMode: boolean | undefined;
    currentUser: any;
    userDoc: any;
    userAuthDoc: any;
    role: any;
    claims: any;
    isLoggedIn: boolean;
    products: Record<string, string>[];
    pgOrganizationUrl: string;
    switchToIconSrc: string;

    @ViewChild('clickMenuTrigger') settingsMenu: MatMenuTrigger;
    private readonly unsubscribeAll: Subject<void>;

    constructor(
        private _themeService: ThemeService,
        private _authService: AuthService,
        private _router: Router,
        public _matDialog: MatDialog,
        private _loggingService: LoggingService,
        private _organizationService: OrganizationService
    ) {
        this.isLoggedIn = false;
        this.switchToIconSrc = 'https://cdn.peoplegrove.com/admin/1698304051863pg-reply-solid.svg';
        this.unsubscribeAll = new Subject();
    }

    async ngOnInit(): Promise<void> {
        try {
            this._themeService.isDarkMode().pipe(takeUntil(this.unsubscribeAll)).subscribe(v => {
                try {
                    this.isDarkMode = v;

                    // Change the Switch to button icon source
                    if (this.isDarkMode) {
                        this.switchToIconSrc = 'https://cdn.peoplegrove.com/admin/1709714829955pg-reply-solid-white.svg';
                    } else {
                        this.switchToIconSrc = 'https://cdn.peoplegrove.com/admin/1698304051863pg-reply-solid.svg';
                    }

                } catch (e) {
                    this._loggingService.logError(e, '/src/app/layout/header/header.component.ts',
                        'ngOnInit', 64);
                }
            });
            this._authService.currentUser().pipe(takeUntil(this.unsubscribeAll)).subscribe(async (user) => {
                try {
                    if (user) {
                        this.currentUser = user;
                        this.userDoc = await this._authService.getUserDoc();
                        this.userAuthDoc = await this._authService.getUserAuthDoc();
                        this.role = this.userAuthDoc.role;
                        this.isLoggedIn = true;
                    }
                } catch (e) {
                    this._loggingService.logError(e, '/src/app/layout/header/header.component.ts', 'ngOnInit', 51);
                }
            });
            this._authService.claims.pipe(takeUntil(this.unsubscribeAll)).subscribe(claims => {
                this.claims = claims;
            });
            this._organizationService.pgOrganizationUrl
                .pipe(takeUntil(this.unsubscribeAll))
                .subscribe(url => {
                    this.pgOrganizationUrl = url;
                });
            this._organizationService.products
                .pipe(takeUntil(this.unsubscribeAll))
                .subscribe(products => {
                    this.products = products;
                });
        } catch (e) {
            this._loggingService.logError(e, '/src/app/layout/header/header.component.ts',
                'ngOnInit', 95);
        }
    }

    ngOnDestroy(): void {
        this.unsubscribeAll.next();
        this.unsubscribeAll.complete();
    }

    openSettingsMenu(): void {
        this.settingsMenu.openMenu();
    }

    toggleMode(): void {
        if (this.isDarkMode) {
            this._themeService.update('light-mode');
        } else {
            this._themeService.update('dark-mode');
        }
    }

    async logOut(): Promise<void> {
        try {
            await this._authService.logout();
        } catch (e) {
            this._loggingService.logError(e, '/src/app/layout/header/header.component.ts', 'logOut', 120);
        }
    }

    async navigateToAuth(): Promise<void> {
        try {
            // Set the current path with query params if present
            this._authService.setRedirectOnLogin(window.location.pathname + window.location.href.split(window.location.pathname)[1]);
            await this._router.navigateByUrl('/auth');
        } catch (e) {
            this._loggingService.logError(e, '/src/app/layout/header/header.component.ts',
                'navigateToAuth', 131);
        }
    }

    async openLoginDialog(redirectRoute: string = this._authService.redirectOnLogin): Promise<void> {
        try {
            const dialogRef = this._matDialog.open(LoginDialogComponent, {
                maxWidth: '800px',
                minWidth: '400px',
                maxHeight: '800px',
                minHeight: '400px',
                disableClose: true
            });

            dialogRef.afterClosed().pipe(takeUntil(this.unsubscribeAll)).subscribe(async v => {
                try {
                    if (v) {
                        const authDoc = await this._authService.getUserAuthDoc();
                        if (authDoc.role === 'student') {
                            this._router.navigate([redirectRoute ? redirectRoute : '/student/dashboard'])
                                .catch(e => {
                                    this._loggingService.logError(e,
                                        '/src/app/layout/header/header.component.ts',
                                        'openLoginDialog', 115);
                                });
                        }
                        else {
                            const settingsDoc = await this._authService.getUserSettingsDoc();

                            if (settingsDoc.firstProjectCreated || settingsDoc.firstCourseCreated) {
                                this._router.navigate([redirectRoute ? redirectRoute : '/app/dashboard'])
                                    .catch(e => {
                                        this._loggingService.logError(e,
                                            '/src/app/layout/header/header.component.ts',
                                            'openLoginDialog', 126);
                                    });
                            }
                            else {
                                if (authDoc.role === 'employer') {
                                    this._router.navigate(['/app/projects/create']).catch(e => {
                                        this._loggingService.logError(e,
                                            '/src/app/layout/header/header.component.ts',
                                            'openLoginDialog', 134);
                                    });
                                }
                                else {
                                    this._router.navigate(['/app/courses/create']).catch(e => {
                                        this._loggingService.logError(e,
                                            '/src/app/layout/header/header.component.ts',
                                            'openLoginDialog', 141);
                                    });
                                }
                            }
                        }

                    }

                } catch (e) {
                    this._loggingService.logError(e, '/src/app/layout/header/header.component.ts',
                        'openLoginDialog', 189);
                }
            });
        } catch (e) {
            this._loggingService.logError(e, '/src/app/layout/header/header.component.ts',
                'openLoginDialog', 194);
        }

    }

    openRegisterDialog(redirectRoute: string = '/'): void {
        try {
            const dialogRef = this._matDialog.open(RegisterDialogComponent, {
                maxWidth: '800px',
                minWidth: '400px',
                maxHeight: '800px',
                minHeight: '400px'
            });
            dialogRef.afterClosed().pipe(takeUntil(this.unsubscribeAll)).subscribe(v => {
                try {
                    if (v) {
                        this._router.navigate([redirectRoute]).catch(e => {
                            this._loggingService.logError(e, '/src/app/layout/header/header.component.ts', 'openRegisterDialog', 114);
                        });
                    }
                } catch (e) {
                    this._loggingService.logError(e, '/src/app/layout/header/header.component.ts',
                        'openRegisterDialog', 216);
                }
            });
        } catch (e) {
            this._loggingService.logError(e, '/src/app/layout/header/header.component.ts',
                'openRegisterDialog', 221);
        }
    }

    async onProductSelect(productObj: Record<string, string>): Promise<void> {
        try {
            const { product } = productObj;

            switch (product) {
                case 'peoplegrove':
                    // Save product switch event in firestore for analytics
                    await this._authService.saveProductSwitchEvent('peoplegrove');
                    window.open(this.pgOrganizationUrl, '_self');
                    break;
                case 'enterprise': {
                    // Save product switch event in firestore for analytics
                    await this._authService.saveProductSwitchEvent('experiential-learning');
                    const token = await this._authService.getSignInCustomToken();
                    const elUrl = _.get(environment, 'elBaseUrl', null);
                    if (!elUrl) { return; }
                    window.open(`${elUrl}/pg-auth/login?token=${token}`, '_self');
                    break;
                }
                default:
                    return;
            }
        } catch (e) {
            this._loggingService.logError(e, '/src/app/layout/header/header.component.ts',
                'onProductSelect', 245);
        }
    }
}
