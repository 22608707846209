import { Injectable, OnDestroy } from '@angular/core';
import { MatLegacySnackBarConfig as MatSnackBarConfig } from '@angular/material/legacy-snack-bar';

import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import * as _ from 'lodash';

import { NetworkErrorService } from './network-error.service';

@Injectable({
    providedIn: 'root'
})
export class SnackBarService implements OnDestroy {
    private readonly _snackBarSubject: ReplaySubject<any>;
    private readonly defaultConfig: MatSnackBarConfig;
    private isOnline = true;
    private unsubscribeAll: Subject<void>;

    constructor(
        private _networkErrorService: NetworkErrorService
    ) {
        this.unsubscribeAll = new Subject<any>();
        // Need to make this replay subject for unit tests
        this._snackBarSubject = new ReplaySubject<any>(1);

        this.defaultConfig = {
            duration: 5000,
            panelClass: 'default-snack-bar-panel',
            verticalPosition: 'top'
        };

        this._networkErrorService.monitor().pipe(
            takeUntil(this.unsubscribeAll)
        ).subscribe((value: boolean) => {
            this.isOnline = value;

            if (!value) {
                this._snackBarSubject.next({
                    message: 'You are offline. Features may be unavailable.',
                    action: 'X',
                    config: {
                        panelClass: 'default-snack-bar-panel',
                        verticalPosition: 'bottom',
                        horizontalPosition: 'left'
                    }
                });
            }
            else {
                this._snackBarSubject.next(null);
            }
        });
    }

    ngOnDestroy(): void {
        this.unsubscribeAll.next();
        this.unsubscribeAll.complete();
    }

    get snackBarSubject(): Subject<any> {
        return this._snackBarSubject;
    }

    open(message: string, action: string = 'X', config?: MatSnackBarConfig): void {
        if (this.isOnline) {
            const newConfig = {};
            _.assign(newConfig, this.defaultConfig, config);

            this._snackBarSubject.next({
                message: message,
                action: action,
                config: newConfig
            });
        }
    }
}
