import { Injectable } from '@angular/core';
import { fromEvent, merge, Observable } from 'rxjs';
import { mapTo } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class NetworkErrorService {
    private readonly _isOnline: Observable<boolean>;

    constructor() {
        const offline$ = fromEvent(window, 'offline').pipe(mapTo(false));
        const online$ = fromEvent(window, 'online').pipe(mapTo(true));
        this._isOnline = merge(offline$, online$);
    }

    monitor(): Observable<boolean> {
        return this._isOnline;
    }
}
