import {Injectable} from '@angular/core';
import {AngularFirestore, DocumentData} from '@angular/fire/compat/firestore';
import {BehaviorSubject, Observable, ReplaySubject, Subject} from 'rxjs';
import {LoggingService} from './logging.service';
import {AuthService} from './auth.service';
import {take, takeUntil} from 'rxjs/operators';
import {DateTime} from 'luxon';
import {FileService} from './file.service';
import firebase from 'firebase/compat';
import DocumentSnapshot = firebase.firestore.DocumentSnapshot;
import {Organization} from '../models/organization.model';
import {AngularFireFunctions} from '@angular/fire/compat/functions';

@Injectable({
    providedIn: 'root'
})
export class OrganizationService {

    private _id: ReplaySubject<string>;
    private _ref: ReplaySubject<any>;
    private _name: ReplaySubject<any>;
    private _domains: ReplaySubject<any>;
    private _customLogoSrc: ReplaySubject<string>;
    private _agreementSigned: ReplaySubject<boolean>;
    private _organizations: BehaviorSubject<any>;
    private _unsubscribeAll: Subject<void>;
    private _pgOrganizationId: ReplaySubject<string>;
    private _pgOrganizationUrl: ReplaySubject<string>;
    private _products: ReplaySubject<Record<string, string>[]>;

    constructor(
        private _firestore: AngularFirestore,
        private _functions: AngularFireFunctions,
        private _authService: AuthService,
        private _loggingService: LoggingService,
        private _fileService: FileService,
    ) {
        this._id = new ReplaySubject<string>(1);
        this._ref = new ReplaySubject<any>(1);
        this._name = new ReplaySubject<any>(1);
        this._domains = new ReplaySubject<any>(1);
        this._customLogoSrc = new ReplaySubject<string>(1);
        this._agreementSigned = new ReplaySubject<boolean>(1);
        this._organizations = new BehaviorSubject<any[]>([]);
        this._unsubscribeAll = new Subject<any>();
        this._pgOrganizationUrl = new ReplaySubject<string>(1);
        this._products = new ReplaySubject<Record<string, string>[]>(1);

        this._init();
    }

    get id(): Observable<string> {
        return this._id.asObservable();
    }

    get ref(): Observable<any> {
        return this._ref.asObservable();
    }

    get name(): Observable<string> {
        return this._name.asObservable();
    }

    get domains(): Observable<string[]> {
        return this._domains.asObservable();
    }

    get customLogoSrc(): Observable<string> {
        return this._customLogoSrc.asObservable();
    }

    get agreementSigned(): Observable<boolean> {
        return this._agreementSigned.asObservable();
    }

    // returns the organizations list as an observable
    get organizations(): Observable<any[]> {
        return this._organizations.asObservable();
    }

    get pgOrganizationUrl(): Observable<string> {
        return this._pgOrganizationUrl.asObservable();
    }

    get products(): Observable<Record<string, string>[]> {
        return this._products.asObservable();
    }

    // get the list of organizations calling firestore directly and order them by name
    async getOrganizations(type?: string): Promise<any[]> {
        try {
            let organizationsQuery: any = this._firestore.collection('organization').ref;

            if (type) {
                organizationsQuery = organizationsQuery.where('type', '==', type);
            }

            const organizations = await organizationsQuery.orderBy('name', 'asc').get();

            // save the response from firestore to the variable
            this._organizations.next(organizations.docs.map((doc) => {
                return {...doc.data() as {}, id: doc.id, ref: doc.ref.path};
            }));

            return organizations.docs.map((doc) => {
                return {...doc.data() as {}, id: doc.id, ref: doc.ref.path};
            });

        } catch (e) {
            this._loggingService.logError(e, '/src/app/services/organization.service.ts', 'getOrganizations', 76);
            throw e;
        }
    }

    // get single organization by id
    async getOrganizationById(id): Promise<any> {
        try {
            const organization = await this._firestore.collection('organization').doc(id).ref
                .get();

            if (organization.exists) {
                const data = organization.data();
                return {id: id, ...data as {}};
            } else {
                return null;
            }

        } catch (e) {
            this._loggingService.logError(e, '/src/app/services/organization.service.ts', 'getOrganizations', 94);
            throw e;
        }
    }

    // get organization
    async getOrganization(): Promise<any> {
        try {
            const user = await this._authService.user.pipe(take(1)).toPromise();
            if (user) {
                const userDoc = await this._authService.getUserDoc();
                const organizationId = userDoc.organizationRef.id;
                const organizationDoc = await this._firestore.collection('organization')
                    .doc(organizationId).get().toPromise();

                const organization = organizationDoc.data() as Organization;
                if (organizationDoc.get('ppAgreement')) {
                    organization.ppAgreement.signedOn = organizationDoc.get('ppAgreement')['signedOn'].toDate();
                }

                return {id: organizationDoc.id, ...organization};
            }

            return {};
        } catch (e) {
            this._loggingService.logError(e, '/src/app/services/organization.service.ts', 'getOrganizations', 112);
            throw e;
        }
    }

    // Update organization doc
    async updateOrganizationDocument(data: any, organizationId?: string): Promise<void> {
        try {
            let id;
            let userId = null;
            if (organizationId) {
                id = organizationId;
            } else {
                const user = await this._authService.user.pipe(take(1)).toPromise();
                userId = user.uid;
                id = await this.id.pipe(take(1)).toPromise();
            }

            let universityLogo;
            if (data['logo'] && data['logo']['name']) {
                universityLogo = data['logo'];
                delete data['logo'];
                const fileObject = await this._fileService.upload(`universityLogos/${universityLogo.name}`, universityLogo);
                // Add file into the document
                data['customLogoSrc'] = fileObject.url;
            } else {
                delete data['logo'];
            }

            await this._firestore.collection('organization').doc(id).update({
                updated: DateTime.utc().toJSDate(),
                updatedBy: userId,
                ...data
            });
        } catch (e) {
            this._loggingService.logError(e, '/src/app/services/auth.service.ts', 'logout', 140);
            throw e;
        }
    }

    async sendDomainUpdateAlertEmail(organizationId: string, domain: string): Promise<void> {
        try {
            return await this._functions.httpsCallable('organization-sendDomainUpdateAlertEmail')(
                {
                    organizationId: organizationId,
                    domain: domain
                }).toPromise();
        } catch (e) {
            this._loggingService.logError(e, '/src/app/services/organization.service.ts', 'sendDomainUpdateAlertEmail', 170);
        }
    }

    private async _init(): Promise<void> {
        try {
            this._authService.currentUser().pipe(takeUntil(this._unsubscribeAll)).subscribe(async (user) => {
                if (user) {
                    const userDoc = await this._authService.getUserDoc();
                    const organizationId = userDoc.organizationRef.id;
                    this._firestore.collection('organization').doc(organizationId).valueChanges()
                        .pipe(takeUntil(this._unsubscribeAll))
                        .subscribe((organizationDoc: DocumentData) => {
                            this._id.next(organizationId);
                            this._ref.next(userDoc.organizationRef);
                            this._domains.next(organizationDoc['domains']);
                            this._name.next(organizationDoc['name']);
                            this._customLogoSrc.next(organizationDoc['customLogoSrc']);
                            this._agreementSigned.next(organizationDoc['ppAgreement'] ? organizationDoc['ppAgreement']['signed'] : false);

                            const products: Record<string, string>[] = [];
                            (organizationDoc.products || []).forEach((productObj: Record<string, string | boolean>) => {
                                const { product, activated } = productObj;
                                if (product === 'enterprise' && activated) {
                                    products.push({
                                        product,
                                        title: 'Experiential Learning',
                                        description: 'Find opportunities to gain hands-on experience',
                                    });
                                }
                            });
                            this._products.next(products);

                            if (organizationDoc.pgOrganizationUrl) {
                                this._pgOrganizationUrl.next(organizationDoc.pgOrganizationUrl);
                            }
                        });
                }
            });
        } catch (e) {
            this._loggingService.logError(e, '/src/app/services/organization.service.ts', '_init', 164);
        }
    }
}
