import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

import { Observable } from 'rxjs';
import { first, take } from 'rxjs/operators';

import { AuthService } from '../services/auth.service';

@Injectable({
    providedIn: 'root'
})
// Prevents guests users from accessing pages that requires a login
export class LoginGuard implements CanActivate {

    constructor(
        private _fireAuth: AngularFireAuth,
        private _router: Router,
        private _authService: AuthService
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        const path = state.url.split('/');
        return this.isLoggedIn(path[1] ? path[1] : '/');
    }

    async isLoggedIn(rootPath: string): Promise<boolean> {
        const user = await this._fireAuth.user.pipe(take(1)).toPromise();
        // If user not logged in redirect to auth page
        if (user === null || user.emailVerified !== true) {
            // Set the current path with query params if present
            this._authService.setRedirectOnLogin(window.location.pathname + window.location.href.split(window.location.pathname)[1]);
            return this._router.navigateByUrl('/auth', {replaceUrl: true});
        }

        // Get the user role
        const role = await this._authService.role.pipe(first(v => !!v)).toPromise();

        // Check if user is a student and accessing non student page
        if (role === 'student' && rootPath !== 'student') {
            // Redirect student to student root
            return this._router.navigateByUrl('/student', {replaceUrl: true});
        }

        // Check is user is NOT a student and accessing student page
        if (role !== 'student' && rootPath === 'student') {
            // Redirect to employer and faculty root
            return this._router.navigateByUrl('/app', {replaceUrl: true});
        }

        return true;
    }
}
