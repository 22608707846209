import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HomePageComponent} from './home-page/home-page.component';
import {SharedModule} from '../shared/shared.module';
import {RouterModule, Routes} from '@angular/router';

import {LoginGuard} from '../guards/login.guard';
import {AuthGuard} from '../guards/auth.guard';

const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        canActivate: [AuthGuard],
        component: HomePageComponent,
        title: 'Home | Student Projects | PeopleGrove'
    },
    {
        path: 'dashboard',
        canActivate: [LoginGuard],
        loadChildren: () => import('./dashboard/dashboard.module').then(module => module.DashboardModule),
        title: 'Dashboard | Student Projects | PeopleGrove'
    },
    {
        path: 'projects',
        loadChildren: () => import('./projects/projects.module').then(module => module.ProjectsModule),
    },
    {
        path: 'courses',
        loadChildren: () => import('./courses/courses.module').then(module => module.CoursesModule),
    },
    {
        path: 'requests',
        canActivate: [LoginGuard],
        loadChildren: () => import('./requests/requests.module').then(module => module.RequestsModule),
        title: 'Requests | Student Projects | PeopleGrove'
    },
    {
        path: 'match/:matchId',
        pathMatch: 'full',
        canActivate: [LoginGuard],
        loadChildren: () => import('./manage-match/manage-match.module').then(module => module.ManageMatchModule),
        title: 'Match Dashboard | Student Projects | PeopleGrove'
    },
    {
        path: 'profile',
        canActivate: [LoginGuard],
        loadChildren: () => import('./profile/profile.module').then(module => module.ProfileModule),
        title: 'Profile | Student Projects | PeopleGrove'
    },
    {
        path: '**',
        redirectTo: ''
    }
];

@NgModule({
    declarations: [
        HomePageComponent,
    ],
    imports: [
        RouterModule.forChild(routes),
        CommonModule,
        SharedModule,
    ],
    exports: [RouterModule]
})
export class ComponentsModule {
}
