import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {LayoutModule} from '@angular/cdk/layout';
import {HeaderComponent} from './layout/header/header.component';
import {SharedModule} from './shared/shared.module';
import {ComponentsModule} from './components/components.module';
import {AuthModule} from './auth/auth.module';
import { HttpClientModule } from '@angular/common/http';

// Firebase
import {AngularFireModule} from '@angular/fire/compat';
import {AngularFirestoreModule} from '@angular/fire/compat/firestore';
import {AngularFireAuthModule} from '@angular/fire/compat/auth';
import {AngularFireFunctionsModule} from '@angular/fire/compat/functions';
import {environment} from '../environments/environment';

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        AngularFireModule.initializeApp(environment.firebaseConfig),
        AngularFirestoreModule,
        AngularFireAuthModule,
        AngularFireFunctionsModule,
        HttpClientModule,

        LayoutModule,
        SharedModule,
        ComponentsModule,
        AuthModule,
    ],
    exports: [
        SharedModule,
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule {
}
