import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';


import { ChipComponent } from './chip.component';
import {CommonModule} from '@angular/common';
import {SharedModule} from '../../shared/shared.module';
import {AuthReportDialogComponent} from '../dialogs/auth-report-dialog/auth-report-dialog.component';
import {AuthReportDialogModule} from '../dialogs/auth-report-dialog/auth-report-dialog.module';

@NgModule({
    declarations: [
        ChipComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        RouterModule,
        AuthReportDialogModule
    ],
    exports: [
        ChipComponent
    ]
})
export class ChipModule {
}
