import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Chip, ChipService } from '../../services/chip.service';
import {MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig} from '@angular/material/legacy-dialog';
import {AuthReportDialogComponent} from '../dialogs/auth-report-dialog/auth-report-dialog.component';

@Component({
    selector: 'app-chip',
    templateUrl: './chip.component.html',
    styleUrls: ['./chip.component.scss']
})
export class ChipComponent implements OnInit, OnDestroy {
    @Input() showChip: boolean | undefined;
    chip: Chip | undefined;

    private _unsubscribeAll: Subject<void>;

    constructor(
        private _dialog: MatDialog,
        private _chipService: ChipService
    ) {
        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void {
        this._chipService.chip
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(chip => this.chip = chip);
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    openReportDialog(): void {
        const dialogRef = this._dialog.open(AuthReportDialogComponent, {
            width: '800px',
            maxWidth: '90%',
            maxHeight: '90%',
            data: {}
        } as MatDialogConfig);
    }
}
