import {Injectable, OnDestroy, OnInit} from '@angular/core';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';

import {ReplaySubject, Subject} from 'rxjs';
import {distinctUntilChanged, skip, takeUntil} from 'rxjs/operators';
import {DateTime} from 'luxon';

import {LoggingService} from './logging.service';
import {SnackBarService} from './snack-bar.service';

@Injectable({
    providedIn: 'root'
})
export class ApplicationUpdateService implements OnDestroy {
    public applicationStartTime: Date;
    private updatePending: boolean;
    private _unsubscribeAll: Subject<void>;

    constructor(
        private _firestore: AngularFirestore,
        private _loggingService: LoggingService,
        private _snackbarService: SnackBarService,
        private _snackbar: MatSnackBar
    ) {
        this.applicationStartTime = DateTime.now().toJSDate();
        this.updatePending = false;
        this._unsubscribeAll = new Subject<any>();
    }

    init(): void {
        try {
            this._firestore.collection('applicationUpdate').doc('projectsPortal').valueChanges()
                .pipe(distinctUntilChanged(), takeUntil(this._unsubscribeAll), skip(1))
                .subscribe(v => {
                    const document: any = v;
                    if (DateTime.fromJSDate(document['updatedOn'].toDate()) > DateTime.fromJSDate(this.applicationStartTime) && this.updatePending === false) {
                        // In case the update value in the firestore doc was updated multiple times within few minutes
                        // Using the flag to prevent closing of existing snackbar which will trigger the page reload immediately
                        this.updatePending = true;
                        this._snackbar.open('New Version Available! Please refresh page to update.', 'Refresh', {
                            duration: 900000, // 15 minutes
                            horizontalPosition: 'center',
                            verticalPosition: 'bottom',
                        }).afterDismissed().subscribe(() => {
                            window.location.reload();
                        });
                    }
            });
        } catch (e) {
            this._loggingService.logError(e, 'src/app/services/application-update.service.ts', 'init', 33);
        }
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

}
