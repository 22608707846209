import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from '../shared/shared.module';
import {RouterModule, Routes} from '@angular/router';

import {AuthComponent} from './auth.component';
import {LoginComponent} from './login/login.component';
import {RegisterComponent} from './register/register.component';
import {LoginDialogComponent} from './dialogs/login-dialog/login-dialog.component';
import {RegisterDialogComponent} from './dialogs/register-dialog/register-dialog.component';
import {ChipModule} from './chip/chip.module';

const routes: Routes = [
    {
        path: '',
        component: AuthComponent,
        title: 'Sign Up | Student Projects | PeopleGrove'
    },
    {
        path: 'verify',
        loadChildren: () => import('./verify/verify.module').then(module => module.VerifyModule),
        title: 'Activate Account | Student Projects | PeopleGrove'
    },
    {
        path: 'forgot-password',
        loadChildren: () => import('./forgot-password/forgot-password.module').then(module => module.ForgotPasswordModule),
        title: 'Forgot Password | Student Projects | PeopleGrove'
    },
    {
        path: 'create-password',
        loadChildren:  () => import('./create-password/create-password.module').then(module => module.CreatePasswordModule),
        title: 'Set Password | Student Projects | PeopleGrove'
    },
    {
        path: 'reset-password',
        loadChildren: () => import('./password-reset/password-reset.module').then(module => module.PasswordResetModule),
        title: 'Reset Password | Student Projects | PeopleGrove'
    },
    {
        path: 'resend-activation',
        loadChildren: () => import('./resend-activation/resend-activation.module').then(module => module.ResendActivationModule),
        title: 'Resend Activation | Student Projects | PeopleGrove'
    },
    {
        path: '**',
        redirectTo: ''
    }
];

@NgModule({
    declarations: [
        AuthComponent,
        LoginComponent,
        RegisterComponent,
        LoginDialogComponent,
        RegisterDialogComponent,
    ],
    imports: [
        RouterModule.forChild(routes),
        CommonModule,
        SharedModule,
        ChipModule
    ]
})
export class AuthModule {
}
