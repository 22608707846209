import { NgModule } from '@angular/core';
import {FileDropDirective} from './file-drop.directive';
import {SimplebarDirective} from './simplebar.directive';

@NgModule({
    declarations: [
        FileDropDirective,
        SimplebarDirective,
    ],
    exports: [
        FileDropDirective,
        SimplebarDirective,
    ]
})
export class DirectivesModule {
}
