<div class="chip-container" *ngIf="showChip" [ngSwitch]="chip.chipCode">


    <div class="chip chip-error" *ngSwitchCase="'auth/login-failed'">
        <div>
            Unable to sign in to your account. Ensure the credentials you entered are correct.
            If you continue to see this message, please
            <a class="report-issue" (click)="openReportDialog()">contact our support team</a>.
        </div>
    </div>

    <div class="chip chip-warn" *ngSwitchCase="'auth/email-already-in-use'">
        <div>
            You already have a Student Opportunity Center account with this email. <br/>
            Sign-In to your account,<br/>
            <a [routerLink]="['/', 'auth', 'forgot-password']">Send password-reset email</a><br/>
            or
            <a [routerLink]="['/', 'auth', 'resend-activation']">Resend activation email</a>
        </div>
    </div>

    <div class="chip chip-warn" *ngSwitchCase="'auth/invalid-email'">
        <div>
            Please provide a valid email address. Use your university provided .edu email address.
        </div>
    </div>

    <div class="chip chip-warn" *ngSwitchCase="'auth/university-not-found'">
        <div>
            Your institution is not a part of the Student Opportunity Center.
            Interested in SOC? Have a representative
            <a href="https://studentopportunitycenter.com/contact/">request a demo</a>.
        </div>
    </div>

    <div class="chip chip-error" *ngSwitchCase="'auth/registration-failed'">
        <div>
            Something went wrong while registering your account.
            If you continue to see this message, please
            <a class="report-issue" (click)="openReportDialog()">contact our support team</a>.
        </div>
    </div>

    <div class="chip chip-warn" *ngSwitchCase="'auth/user-disabled'">
        <div>
            Your account has been disabled.
            Please contact your organization's administrator for access.
        </div>
    </div>

    <div class="chip chip-warn" *ngSwitchCase="'auth/university-disabled'">
        <div>
            Your institution is no longer subscribed to Student Opportunity Center.
            To renew service, have a representative
            <a href="https://studentopportunitycenter.com/contact/">contact us</a>.
        </div>
    </div>

    <div class="chip chip-warn" *ngSwitchCase="'auth/user-not-found'">
        <div>
            We couldn't find an account associated with this email address.
            Looking to sign up instead?
        </div>
    </div>

    <div class="chip chip-warn" *ngSwitchCase="'auth/invalid-action-code'">
        <div>
            The link you are trying to use is not valid. This can happen if the link was copied incorrectly
            or an old version of the link was used. You can try requesting a new link, or
            <a class="report-issue" (click)="openReportDialog()">contact our support team</a>.
        </div>
    </div>

    <div class="chip chip-warn" *ngSwitchCase="'auth/email-not-verified'">
        <div>
            Your account isn't activated yet. Please check your email for the
            activation link sent when you registered, or <br/>
            <a [routerLink]="['/', 'auth', 'resend-activation']">resend your activation email</a>.
            If you need further help, please
            <a class="report-issue" (click)="openReportDialog()">contact our support team</a>.
        </div>
    </div>

    <div class="chip chip-warn" *ngSwitchCase="'auth/invalid-verification-token'">
        <div>
            The link you are trying to use is no longer valid.
            <a [routerLink]="['/', 'auth', 'resend-activation']">Resend activation email</a>?
        </div>
    </div>

    <div class="chip chip-warn" *ngSwitchCase="'auth/invalid-reset-token'">
        <div>
            The link you are trying to use is no longer valid.
            <a [routerLink]="['/', 'auth', 'forgot-password']">Resend password-reset email</a>?
        </div>
    </div>

    <div class="chip chip-warn" *ngSwitchCase="'auth/invalid-create-token'">
        <div>
            The link you are trying to use is no longer valid.
            <a [routerLink]="['/', 'auth', 'forgot-password']">Send password-reset email</a>?
        </div>
    </div>

    <div class="chip chip-warn" *ngSwitchCase="'auth/email-already-verified'">
        <div>
            Your account has already been verified
        </div>
    </div>

    <div class="chip chip-error" *ngSwitchCase="'auth/send-verify-email-failed'">
        <div>
            Something went wrong while sending your email.
            If you continue to see this message, please
            <a class="report-issue" (click)="openReportDialog()">contact our support team</a>.
        </div>
    </div>

    <div class="chip chip-error" *ngSwitchCase="'auth/wrong-password'">
        <div>
            Some of your information is incorrect. Need to
            <a [routerLink]="['/', 'auth', 'forgot-password']">reset your password</a>?
        </div>
    </div>

    <div class="chip chip-success" *ngSwitchCase="'auth/register/success'">
        <div>
            <p style="font-weight: 500;">
                Almost there!
            </p>
            <p>
                We sent an email verification link to
                <span style="font-weight: 500;">{{ chip.chipParams.email }}</span>
            </p>
            <p>
                Please allow 5-10 minutes for the email to arrive
                and also check your organization's spam filters.
            </p>
            <p>
                Don't see a verification email?
                <a [routerLink]="['/', 'auth', 'resend-activation']">Resend verification email</a>.
            </p>
        </div>
    </div>

    <div class="chip chip-success" *ngSwitchCase="'auth/verify-email/success'">
        <div>
            <span style="font-weight: 500;">Success!</span>
            Your account was successfully registered with Student Opportunity Center.
        </div>
    </div>

    <div class="chip chip-error" *ngSwitchCase="'auth/email-verification-failed'">
        <div>
            Something went wrong while verifying your email address.
            You can try
            <a [routerLink]="['/', 'auth', 'resend-activation']">resending your verification email</a>,
            or if you continue to see this message, please
            <a class="report-issue" (click)="openReportDialog()">contact our support team</a>.
        </div>
    </div>

    <div class="chip chip-error" *ngSwitchCase="'auth/password-reset-failed'">
        <div>
            Something went wrong while resetting your password.
            You can try
            <a [routerLink]="['/', 'auth', 'forgot-password']">resending your password-reset email</a>,
            or if you continue to see this message, please
            <a class="report-issue" (click)="openReportDialog()">contact our support team</a>.
        </div>
    </div>

    <div class="chip chip-success" *ngSwitchCase="'auth/send-password-reset/success'">
        <div>
            <p style="font-weight: 500;">
                Almost there!
            </p>
            <p>
                We sent a password reset link to
                <span style="font-weight: 500;">{{ chip.chipParams.email }}</span>
            </p>
            <p>
                Please allow 5-10 minutes for the email to arrive
                and also check your organization's spam filters.
            </p>
        </div>
    </div>

    <div class="chip chip-success" *ngSwitchCase="'auth/reset-password/success'">
        <div>
            Your password has been successfully updated. You may now log in with your new password.
        </div>
    </div>

    <div class="chip chip-success" *ngSwitchCase="'auth/create-password/success'">
        <div>
            Your password has been successfully created. You may now log in with your new password.
        </div>
    </div>

    <div class="chip chip-success" *ngSwitchCase="'auth/trial/success'">
        <div>
            You have successfully sent a request for a trial version of Student Opportunity Center! Please wait
            for a confirmation on approval of your request.
        </div>
    </div>

    <div class="chip chip-warn" *ngSwitchCase="'auth/user-already-exists'">
        <div>
            You have already registered for Student Opportunity Center!
            Sign-In to your account,<br/>
            <a [routerLink]="['/', 'auth', 'forgot-password']">Send password-reset email</a><br/>
            or
            <a [routerLink]="['/', 'auth', 'resend-activation']">Resend activation email</a>
        </div>
    </div>

    <div class="chip chip-error" *ngSwitchCase="'auth/captcha-not-verified'">
        <div>
            Captcha verification failed. Please wait a minute, then reload the page and try again.<br/>
            If you continue to see this message, please
            <a class="report-issue" (click)="openReportDialog()">contact our support team</a>.
        </div>
    </div>

    <div class="chip chip-error" *ngSwitchCase="'auth/2fa-otp-failed'">
        <div>
            Authentication failed. Please try again by entering the code you see in your authenticator app.<br/>
            If you continue to see this message, please
            <a class="report-issue" (click)="openReportDialog()">contact our support team</a>.
        </div>
    </div>

    <div class="chip chip-error" *ngSwitchCase="'auth/invalid-custom-token'">
        <div>
            Sign-In Failed. Please try again with the right credentials.
            If you continue to see this message, please
            <a class="report-issue" (click)="openReportDialog()">contact our support team</a>.
        </div>
    </div>

    <div class="chip chip-error" *ngSwitchDefault>
        <div>
            Something went wrong. A record of the issue has been sent to us for review.
            If you continue to see this message, please
            <a class="report-issue" (click)="openReportDialog()">contact our support team</a>.
        </div>
    </div>

</div>
