import {Company} from './company.model';
import * as _ from 'lodash';
import {DateTime} from 'luxon';

export interface Project {
    // meta-data
    created?: Date;
    createdBy?: string;
    updated?: Date;
    updatedBy?: string;

    organizationId?: string;
    targetOrganizationIds?: string[];

    // data
    id?: string;
    title: string;
    adminEmails: string[];
    description: string;
    projectLogo: string;
    startDate?: Date;
    endDate?: Date;
    semester?: 'spring' | 'summer' | 'fall' | 'winter';
    projectDuration?: number;
    hardDeadline?: boolean;
    files?: File[];
    tags?: string[];
    studentEducationLevel?: string;
    studentExperience?: string;
    studentTeamSize?: number;
    studentHourPerTeam?: number;
    numberOfStudentTeam?: number;
    preferredInstitutionName?: string;
    preferredInstitutionType?: string;
    preferredInstitutionLocation?: string;
    preferredInstitutionFaculty?: string;
    preferredCourseSubjects?: string;
    additionalInformation?: string;

    companyDetails: Company;
    status: string;
    isLocked: boolean;
    lockedBy: string;
    lockedOn: Date;
}

interface ProjectElasticInterface {
    type?: string;
    created?: string;
    created_by?: any;
    create_method?: string;
    updated?: string;
    updated_by?: any;
    id?: string;
    organization_id?: string;
    target_organization_ids?: string[];

    title?: string;
    adminEmails?: string[];
    description?: string;
    project_logo?: string;
    start_date?: string;
    end_date?: string;
    semester?: 'spring' | 'summer' | 'fall' | 'winter';
    project_duration?: number;
    hard_deadline?: boolean;
    tags?: string[];
    additional_information?: string;
    files?: string[];

    company_name?: string;
    company_industry?: string;
    company_url?: string;
    company_address?: {
        address?: string;
        city?: string;
        state?: string;
        zipcode?: string;
        coordinates?: string;
    };
    company_logo?: string;

    student_education_level?: string;
    student_experience?: string;
    student_hour_per_team?: number;
    student_team_size?: number;
    number_of_student_team?: number;

    preferred_course_subjects?: string[];
    preferred_institution_location?: string;
    preferred_institution_type?: string;

    status?: string;

    isLocked?: boolean;
    lockedBy?: string;
    lockedOn?: Date;
}

export class ProjectElasticDocument implements ProjectElasticInterface {
    type?: string = undefined;
    created?: string = undefined;
    created_by?: any = undefined;
    create_method?: string = undefined;
    updated?: string = undefined;
    updated_by?: any = undefined;
    id?: string = undefined;
    organization_id?: string = undefined;
    target_organization_ids?: string[] = undefined;

    title?: string = undefined;
    admin_emails?: string[] = undefined;
    description?: string = undefined;
    project_logo?: string = undefined;
    start_date?: string = undefined;
    end_date?: string = undefined;
    semester?: 'spring' | 'summer' | 'fall' | 'winter' = undefined;
    hard_deadline?: boolean = undefined;
    project_duration?: number = undefined;
    tags?: string[] = undefined;
    additional_information?: string = undefined;
    files?: string[] = undefined;

    company_name?: string = undefined;
    company_industry?: string = undefined;
    company_url?: string = undefined;
    company_address?: {
        address?: string;
        city?: string;
        state?: string;
        zipcode?: string;
        coordinates?: string;
    } = undefined;
    company_logo?: string = undefined;

    student_education_level?: string = undefined;
    student_experience?: string = undefined;
    student_hour_per_team?: number = undefined;
    student_team_size?: number = undefined;
    number_of_student_team?: number = undefined;

    preferred_course_subjects?: string[] = undefined;
    preferred_institution_location?: string = undefined;
    preferred_institution_type?: string = undefined;

    status?: string = undefined;

    is_locked?: boolean = undefined;
    locked_by?: string = undefined;
    locked_on?: Date = undefined;

    constructor(source: any = {}) {
        _.forEach(Object.getOwnPropertyNames(this), (propertyName) => {
            if (propertyName in source && !_.isUndefined(source[propertyName])) {
                this[propertyName] = source[propertyName];
            } else if (_.isUndefined(this[propertyName])) {
                delete this[propertyName];
            }
        });
    }

    toProject(): Project {
        const project = {};

        _.set(project, 'created', DateTime.fromISO(this.created).toJSDate());
        _.set(project, 'createdBy', this.created_by ? this.created_by.id : null);

        _.set(project, 'updated', DateTime.fromISO(this.updated).toJSDate());
        _.set(project, 'updatedBy', this.updated_by ? this.updated_by.id : null);

        _.set(project, 'id', this.id);
        _.set(project, 'organizationId', this.organization_id);
        _.set(project, 'title', this.title);
        _.set(project, 'adminEmails', this.admin_emails);
        _.set(project, 'hardDeadline', this.hard_deadline);
        _.set(project, 'description', this.description);
        _.set(project, 'projectLogo', this.project_logo);
        _.set(project, 'startDate', this.start_date ? DateTime.fromISO(this.start_date).toJSDate() : null);
        _.set(project, 'endDate', this.end_date ? DateTime.fromISO(this.end_date).toJSDate() : null);
        _.set(project, 'semester', this.semester);
        _.set(project, 'projectDuration', this.project_duration);
        _.set(project, 'additionalInformation', this.additional_information);
        _.set(project, 'tags', this.tags);
        _.set(project, 'companyDetails.name', this.company_name);
        _.set(project, 'companyDetails.industry', this.company_industry);
        _.set(project, 'companyDetails.url', this.company_url);
        _.set(project, 'companyDetails.companyLogo', this.company_logo);
        _.set(project, 'studentEducationLevel', this.student_education_level);
        _.set(project, 'studentHourPerTeam', this.student_hour_per_team);
        _.set(project, 'studentTeamSize', this.student_team_size);
        _.set(project, 'numberOfStudentTeam', this.number_of_student_team);
        _.set(project, 'studentExperience', this.student_experience);
        _.set(project, 'preferredCourseSubjects', this.preferred_course_subjects);
        _.set(project, 'preferredInstitutionType', this.preferred_institution_type);
        _.set(project, 'preferredInstitutionLocation', this.preferred_institution_location);
        _.set(project, 'status', this.status);
        _.set(project, 'isLocked', this.is_locked);
        _.set(project, 'lockedBy', this.locked_by);
        _.set(project, 'lockedOn', this.locked_on);

        return project as Project;
    }
}
