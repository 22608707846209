<div fxLayout="column" fxLayoutAlign="start stretch">
    <!--  Welcome Section-->
    <div class="wrapper-container welcome-container" fxLayout="row" fxLayoutAlign="space-between start">
        <div class="m-10pc" [ngClass.lt-md]="'my-20pc'" [ngClass.lt-sm]="'my-30pc'"
             fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="16px">

            <div class="headline" fxLayout="column" fxLayoutAlign="start start">
                <div>Connecting</div>
                <div class="theme-accent-color-text">Company Projects</div>
                <div>& <span class="theme-accent-color-text">University Classes</span></div>
            </div>

            <div class="max-w-500">
                Project-Based Learning (PBL) consists of students designing, developing, and constructing hands-on
                solutions to a business problem.
            </div>

            <div fxLayout="row wrap" fxLayoutAlign="start center">
                <ng-container *ngIf="!isLoggedIn">
                    <div class="my-8">
                        <button
                            mat-raised-button color="primary"
                            (click)="onProjectButtonClick()">
                            <div class="py-8">I HAVE A PROJECT</div>
                        </button>
                    </div>
                    <div class="mx-8" style="color: grey">OR</div>
                    <div class="my-8">
                        <button
                            mat-raised-button color="primary"
                            (click)="onCourseButtonClick()">
                            <div class="py-8">I HAVE A COURSE</div>
                        </button>
                    </div>
                </ng-container>
                <ng-container *ngIf="isLoggedIn && role === 'employer'">
                    <div class="my-8">
                        <button
                            mat-raised-button color="primary"
                            (click)="onProjectButtonClick()">
                            <div class="py-8">I HAVE A PROJECT</div>
                        </button>
                    </div>
                    <div class="mx-8" style="color: grey">OR</div>
                    <div class="my-8">
                        <button
                            mat-raised-button color="primary"
                            [routerLink]="['courses', 'search']">
                            <div class="py-8">SEARCH COURSES</div>
                        </button>
                    </div>
                </ng-container>
                <ng-container *ngIf="isLoggedIn && role !== 'employer'">
                    <div class="my-8">
                        <button
                            mat-raised-button color="primary"
                            (click)="onCourseButtonClick()">
                            <div class="py-8">I HAVE A COURSE</div>
                        </button>
                    </div>
                    <div class="mx-8" style="color: grey">OR</div>
                    <div class="my-8">
                        <button
                            mat-raised-button color="primary"
                            [routerLink]="['projects', 'search']">
                            <div class="py-8">SEARCH PROJECTS</div>
                        </button>
                    </div>
                </ng-container>
            </div>
        </div>

        <div style="overflow: visible" fxHide.lt-md>
            <img width="100%" height="100%" alt="Image"
                 src="https://firebasestorage.googleapis.com/v0/b/soc-firebase.appspot.com/o/projectsPortal%2Flanding_page%2FprojectsPortalHomePage1.png?alt=media&token=77a60a91-e4f7-4171-a241-a765d49d10ee">
        </div>
    </div>

    <!-- Why and How Section -->
    <div class="wrapper-container background-description mt-32">
        <div class="mx-10pc my-48" [ngClass.lt-sm]="'mx-0pc'"
             fxLayout="column" fxLayoutAlign="start stretch">

            <div fxLayout="row wrap" fxLayoutAlign="start start"
                 fxLayout.lt-md="column" fxLayoutAlign.lt-md="center center">

                <!-- WHY SECTION -->
                <div class="section-box" fxLayout="column" fxLayoutAlign="start center">
                    <div class="section-header mb-8" fxLayout="row" fxLayoutAlign="center center">
                        <h1>WHY</h1>
                    </div>
                    <div class="mb-16"
                         fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="48px">
                        <div fxLayout="column">
                            <img class="mb-8" height="40px" width="40px" alt="Why Icon"
                                 src="https://firebasestorage.googleapis.com/v0/b/soc-firebase.appspot.com/o/projectsPortal%2Flanding_page%2Ftalent_pipeline_icon.png?alt=media&token=8eb8942b-5b23-4e55-8984-499665ffef8a">
                            <div>
                                <strong>Scalable talent pipelines</strong>
                                to meaningfully connect with talent early
                            </div>
                        </div>
                        <div fxLayout="column">
                            <img class="mb-8" height="40px" width="40px" alt="Why Icon 2"
                                 [src]="'https://firebasestorage.googleapis.com/v0/b/soc-firebase.appspot.com/o/projectsPortal%2Flanding_page%2Fcreative_projects_icon.png?alt=media&token=a238561c-e9eb-4b44-adbf-a8a16e6d2fa2'">

                            <div>
                                <strong>Get creative projects done</strong> by students with unique perspectives
                            </div>
                        </div>
                    </div>
                </div>

                <!-- HOW SECTION -->
                <div class="section-box-2" fxLayout="column" fxLayoutAlign="start center">
                    <div class="section-header mb-8" fxLayout="row" fxLayoutAlign="center center">
                        <h1>HOW</h1>
                    </div>
                    <div class="mb-16"
                         fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="48px">
                        <div class="ml-16" fxLayout="column">
                            <img class="mb-8" height="40px" width="40px" alt="How Icon"
                                 src="https://firebasestorage.googleapis.com/v0/b/soc-firebase.appspot.com/o/projectsPortal%2Flanding_page%2Fweekly_commitment_icon.png?alt=media&token=85cf6a93-fbb0-49f3-862a-8e757ebab2db">
                            <div>
                                <strong>1-2 hours/week commitment</strong>
                                meeting with the class to help the students complete your project
                            </div>
                        </div>
                        <div fxLayout="column">
                            <img class="mb-8" height="40px" width="40px" alt="How Icon 2"
                                 [src]="'https://firebasestorage.googleapis.com/v0/b/soc-firebase.appspot.com/o/projectsPortal%2Flanding_page%2Fproject_timeline_icon.png?alt=media&token=cd37ec2d-8ce6-413d-b355-972d48d6a43c'">
                            <div>
                                <strong>4-12 week project timelines</strong> completing
                                company-defined deliverables
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- DESCRIPTIONS SECTIONS -->
            <div class="my-32" fxLayout="row" fxLayoutAlign="space-evenly center"
                 fxLayout.lt-md="column">
                <div fxLayout="row">
                    <span class="mr-4 mt-8" style="font-weight: 20; font-size: 40px; color:white">
                        #
                    </span>

                    <div style="width: 250px">
                        <h2>
                            <strong>
                                <span class="section-header"> One Agreement,</span><br/>
                                Hundreds of Colleges</strong></h2>

                        <div>
                            SOC serves as the intermediary, allowing you to sign one agreement and work with hundreds
                            of universities/colleges across the US.
                        </div>
                    </div>
                </div>

                <div class="mt-32" fxLayout="row">
                    <span class="mr-4 mt-8" style="font-weight: 20; font-size: 40px; color:white">
                        #
                    </span>
                    <div style="width: 250px">
                        <h2><strong>Bespoke Project <br/>
                            <span class="section-header">Planning</span></strong></h2>
                        <div>
                            Using SOC’s library of project templates, best-practices and expert guidance, SOC puts
                            together
                            the ideal project package for college classes to complete. For less time and effort than
                            managing a single summer intern you can work with dozens of talented students at a time.
                        </div>
                    </div>
                </div>

                <div class="mt-64" fxLayout="row">
                    <span class="mr-4 mt-8" style="font-weight: 20; font-size: 40px; color:white">
                        #
                    </span>
                    <div style="width: 250px">
                        <h2><strong>Streamlined Project <br/>
                            <span class="section-header">Facilitation</span></strong></h2>

                        <div>
                            SOC provides project facilitation support for all our employer partners. From onboarding
                            students, managing communication channels, and ensuring KPIs are met, SOC is there to ensure
                            a
                            smooth process from project launch to completion.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Projects and Courses -->
    <div class="wrapper-container">
        <div class="mx-64 my-48">
            <!--  Projects Section -->
            <div class="projects-courses-header theme-accent-color-text" fxLayout="row"
                 fxLayoutAlign="center center">
                {{(role === 'employer' && this.organizationName) ? (this.organizationName | uppercase) : 'LATEST'}}
                PROJECTS
            </div>
            <div *ngIf="projectsLoading" class="my-32" fxLayoutAlign="center center">
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </div>
            <div *ngIf="!projectsLoading " class="m-8" fxLayout="column" fxLayoutAlign="center center">
                <div class="m-16" fxLayout="row wrap" fxLayoutGap="16px" fxLayoutAlign="flex-start"
                     fxLayout.lt-sm="column">

                    <ng-container *ngIf="!projects || projects.length === 0">
                        <div *ngIf="(role === 'employer' && this.organizationName) else noProjectResults">
                            There are currently no projects created in your organization. Click
                            <a [href]="'/app/projects/create'">here</a> to create one.
                        </div>
                        <ng-template #noProjectResults>
                            No results found
                        </ng-template>
                    </ng-container>

                    <mat-card *ngFor="let project of projects" class="popular-card cursor-pointer"
                              (click)="routeToProject(project.id)">
                        <mat-card-header class="card-header">
                            <mat-card-title class="card-content-title theme-primary-color-text">
                                <strong>{{project.title}}</strong>
                            </mat-card-title>
                        </mat-card-header>
                        <mat-card-content class="card-content" fxLayout="column" fxLayoutAlign="space-between start">
                            <div class="card-content-description">
                                {{project.description}}
                            </div>

                            <div fxLayoutAlign="end center">
                                <!-- TAGS -->
                                <mat-chip-list>
                                    <div *ngFor="let tag of project.tags; let i = index">
                                        <mat-chip *ngIf="i<2">
                                            {{tag}}
                                        </mat-chip>
                                    </div>
                                </mat-chip-list>
                            </div>

                        </mat-card-content>

                        <div class="card-footer" fxLayout="column" fxLayoutAlign="start stretch">
                            <mat-divider></mat-divider>
                            <div class="mt-16" fxLayout="row" fxLayoutAlign="start center">
                                <img class="card-image" fxFlex="20" alt="Company Logo"
                                     [src]="project.companyDetails.companyLogo ? project.companyDetails.companyLogo : '/assets/images/placeholder/default-company-logo.png'">
                                <span class="card-organization-name theme-primary-color-text">
                                    {{project.companyDetails.name}}
                                </span>
                            </div>
                        </div>
                    </mat-card>
                </div>
                <div fxLayoutAlign="center center">
                    <button *ngIf="projects && projects.length > 0 && role === 'employer'"
                            aria-label="View all courses"
                            mat-stroked-button color="primary" [routerLink]="['projects', 'list', 'organization']"
                            matTooltip="View More">
                        VIEW ALL PROJECTS
                    </button>
                    <button *ngIf="projects && projects.length > 0 && role !== 'employer'"
                            aria-label="View all courses"
                            mat-stroked-button color="primary" [routerLink]="['projects', 'search']"
                            matTooltip="View More">
                        VIEW ALL PROJECTS
                    </button>
                </div>
            </div>

            <div class="my-48" fxLayout="row" fxLayoutAlign="center center">
                <mat-divider style="border-top-color: #F7EC59; border-top-width: 4px;"
                             fxFlex="20" fxFlex.lt-md="40">
                </mat-divider>
            </div>

            <!--  Course Section -->
            <div class="projects-courses-header theme-accent-color-text" fxLayout="row"
                 fxLayoutAlign="center center">
                {{(role !== 'employer' && this.organizationName) ? (this.organizationName | uppercase) : 'LATEST'}}
                COURSES
            </div>
            <div *ngIf="coursesLoading" class="my-32" fxLayoutAlign="center center">
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </div>
            <div *ngIf="!coursesLoading" class="m-8" fxLayout="column" fxLayoutAlign="center center">
                <div class="m-16" fxLayout="row wrap" fxLayoutGap="16px" fxLayoutAlign="flex-start"
                     fxLayout.lt-sm="column">

                    <ng-container *ngIf="!courses || courses.length === 0">
                        <div *ngIf="role !== 'employer' && this.organizationName else noCourseResults">
                            There are currently no courses created in your organization. Click
                            <a [href]="'/app/courses/create'">here</a> to create one.
                        </div>
                        <ng-template #noCourseResults>
                            No results found
                        </ng-template>
                    </ng-container>

                    <mat-card *ngFor="let course of courses" class="popular-card cursor-pointer"
                              (click)="routeToCourse(course.id)">
                        <mat-card-header class="card-header">
                            <mat-card-title>
                                <div class="card-content-title theme-primary-color-text" fxLayout="column"
                                     fxLayoutAlign="start start">
                                    <span>{{course.courseNumber}}</span>
                                    <span><strong>{{course.courseName}}</strong></span>
                                </div>
                            </mat-card-title>
                        </mat-card-header>

                        <mat-card-content class="card-content" fxLayout="column" fxLayoutAlign="space-between start">
                            <div class="card-content-description">
                                {{course.description}}
                            </div>
                            <div fxLayoutAlign="end center">
                                <!-- TAGS -->
                                <mat-chip-list>
                                    <div *ngFor="let tag of course.tags; let i = index">
                                        <mat-chip *ngIf="i<2">
                                            {{tag}}
                                        </mat-chip>
                                    </div>
                                </mat-chip-list>
                            </div>
                        </mat-card-content>

                        <div class="card-footer" fxLayout="column" fxLayoutAlign="start stretch">
                            <mat-divider></mat-divider>
                            <div class="mt-16" fxLayout="row" fxLayoutAlign="start center">
                                <img class="card-image" fxFlex="20" alt="University Logo"
                                     [src]="course.facultyDetails.universityLogo ? course.facultyDetails.universityLogo : '/assets/images/placeholder/default-university-logo.png'">
                                <span class="card-organization-name theme-primary-color-text"
                                      style="text-overflow: ellipsis">
                                    {{course.facultyDetails.university}}
                                </span>
                            </div>
                        </div>
                    </mat-card>
                </div>
                <div fxLayoutAlign="center center">
                    <button *ngIf="courses && courses.length > 0 && (role !== 'employer' && isLoggedIn)" class="py-8" aria-label="View all courses"
                            mat-stroked-button color="primary" [routerLink]="'courses/list/organization'" matTooltip="View More">
                        VIEW ALL COURSES
                    </button>
                    <button *ngIf="courses && courses.length > 0 && (role === 'employer' || !isLoggedIn)" class="py-8" aria-label="View all courses"
                            mat-stroked-button color="primary" [routerLink]="'courses/search'" matTooltip="View More">
                        VIEW ALL COURSES
                    </button>
                </div>
            </div>
        </div>
    </div>

    <!-- How it works Section-->
    <div class="wrapper-container background-works ">
        <div class="mx-64 my-48">
            <div fxLayout="column" fxLayoutAlign="start stretch">

                <div class="mb-48" fxLayoutAlign="center center">
                    <div class="mb-16" style="font-size: 2rem; line-height: 2rem;">
                        <span class="section-header" style="text-decoration-color: #00FDDC;">
                            HOW
                        </span>
                        IT WORKS
                    </div>
                </div>

                <div fxLayout="row" fxLayoutAlign="space-evenly start"
                     fxLayout.lt-md="column" fxLayoutAlign.lt-md="space-evenly center" fxLayoutGap.lt-md="32px">

                    <div class="max-w-250">
                        <div fxLayout="column" fxLayoutAlign="start stretch"
                             fxLayout.lt-md="row" fxLayoutAlign.lt-md="center start" fxLayoutGap.lt-md="16px">
                            <img class="mb-8" height="50px" width="50px" alt="Matching Icon"
                                 src="https://firebasestorage.googleapis.com/v0/b/soc-firebase.appspot.com/o/projectsPortal%2Flanding_page%2Fmatching_icon.png?alt=media&token=4085cb70-7bab-4671-b3a5-6f8520d3afe6">
                            <div>
                                <span class="projects-courses-header">WEEK 1-2</span>
                                <h2>Matching</h2>
                            </div>
                        </div>
                        <div class="mb-8">
                            <ul>
                                <li>Create 2-3 sentence project idea</li>
                                <li>Identify preferred courses/colleges</li>
                                <li>Review compatibility</li>
                                <li>Match project to class</li>
                            </ul>
                        </div>
                        <a target="_blank"
                           href="https://firebasestorage.googleapis.com/v0/b/soc-firebase.appspot.com/o/projectsPortal%2FEXAMPLE%20Business%20Project%20%231.docx?alt=media&token=4f39bce2-d739-4cb8-8dd8-145d38b75729">
                            <button
                                mat-raised-button
                                color="primary">
                                PROJECT IDEA EXAMPLE
                            </button>
                        </a>
                    </div>

                    <div class="max-w-250">
                        <div fxLayout="column" fxLayoutAlign="start stretch"
                             fxLayout.lt-md="row" fxLayoutAlign.lt-md="center start" fxLayoutGap.lt-md="16px">
                            <img class="mb-8" height="50px" width="50px" alt="Planning Icon"
                                 src="https://firebasestorage.googleapis.com/v0/b/soc-firebase.appspot.com/o/projectsPortal%2Flanding_page%2Fplanning_icon.png?alt=media&token=e2dd4ba5-2e80-44e5-85b8-19b1fe7f09b6">
                            <div>
                                <span class="projects-courses-header">WEEK 3-4</span>
                                <h2>Planning</h2>
                            </div>
                        </div>
                        <ul>
                            <li><strong>Size:</strong> 10 - 30 students? Group or individual projects?</li>
                            <li><strong>Length:</strong> 4 - 12 week project duration? Start date?</li>
                            <li><strong>Final Deliverable:</strong> Report and/or presentation from students?</li>
                            <li><strong>Supporting Materials:</strong> Upload info/docs for students</li>
                        </ul>
                    </div>

                    <div class="max-w-250">
                        <div fxLayout="column" fxLayoutAlign="start stretch"
                             fxLayout.lt-md="row" fxLayoutAlign.lt-md="center start" fxLayoutGap.lt-md="16px">
                            <img class="mb-8" height="50px" width="50px" alt="Implementation Icon"
                                 src="https://firebasestorage.googleapis.com/v0/b/soc-firebase.appspot.com/o/projectsPortal%2Flanding_page%2Fimplementation_icon.png?alt=media&token=83523653-e5af-4a6e-adf6-6459d1f3e412">
                            <div>
                                <span class="projects-courses-header">WEEK 4-12</span>
                                <h2>Implementation</h2>
                            </div>
                        </div>
                        <ul>
                            <li>1 hour weekly check-in calls between company and class</li>
                            <li>1 - 2 hour total weekly company commitment</li>
                            <li>SOC handles all other support</li>
                        </ul>
                    </div>

                    <div class="max-w-250">
                        <div fxLayout="column" fxLayoutAlign="start stretch"
                             fxLayout.lt-md="row" fxLayoutAlign.lt-md="center start" fxLayoutGap.lt-md="16px">
                            <img class="mb-8" height="50px" width="50px" alt="Completion Icon"
                                 src="https://firebasestorage.googleapis.com/v0/b/soc-firebase.appspot.com/o/projectsPortal%2Flanding_page%2Fcompletion_icon.png?alt=media&token=7eff7809-fc14-4712-8a9f-67edec0ae26b">
                            <div>
                                <span class="projects-courses-header">FINAL WEEK</span>
                                <h2>Completion</h2>
                            </div>
                        </div>
                        <div class="mb-8">
                            <ul>
                                <li>Final student presentations and/or reports submitted</li>
                                <li>Review and assess deliverables</li>
                                <li>Provide feedback to faculty and students</li>
                            </ul>
                        </div>

                        <a target="_blank"
                           href="https://firebasestorage.googleapis.com/v0/b/soc-firebase.appspot.com/o/projectsPortal%2FDelivarable%20Example%20-%20TreeID%20%26%20Summer%20Camps.pdf?alt=media&token=3574c091-d3a6-4c1d-a139-04c6c92cb030">
                            <button
                                mat-raised-button
                                color="primary">
                                DELIVERABLE EXAMPLE
                            </button>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- FOOTER -->
    <div class="wrapper-container">
        <div class="footer-container" fxLayout="column" fxLayoutAlign="center center">


            <div class="projects-courses-header theme-accent-color-text"
                 [ngClass.lt-sm]="'ml-16'" style="font-size: 2rem; line-height: 2rem">
                LET'S GET STARTED
            </div>
            <div class="mt-32" [ngClass.lt-sm]="'ml-16'" fxLayout="row wrap" fxLayoutAlign="start center">
                <ng-container *ngIf="!isLoggedIn">
                    <div class="my-8">
                        <button
                            mat-raised-button color="primary"
                            (click)="onProjectButtonClick()">
                            <div class="py-8">I HAVE A PROJECT</div>
                        </button>
                    </div>
                    <div class="mx-8" style="color: white">OR</div>
                    <div class="my-8">
                        <button
                            mat-raised-button color="primary"
                            (click)="onCourseButtonClick()">
                            <div class="py-8">I HAVE A COURSE</div>
                        </button>
                    </div>
                </ng-container>
                <ng-container *ngIf="isLoggedIn && role === 'employer'">
                    <div class="my-8">
                        <button
                            mat-raised-button color="primary"
                            (click)="onProjectButtonClick()">
                            <div class="py-8">I HAVE A PROJECT</div>
                        </button>
                    </div>
                    <div class="mx-8" style="color: grey">OR</div>
                    <div class="my-8">
                        <button
                            mat-raised-button color="primary"
                            [routerLink]="['courses', 'search']">
                            <div class="py-8">SEARCH COURSES</div>
                        </button>
                    </div>
                </ng-container>
                <ng-container *ngIf="isLoggedIn && role !== 'employer'">
                    <div class="my-8">
                        <button
                            mat-raised-button color="primary"
                            (click)="onCourseButtonClick()">
                            <div class="py-8">I HAVE A COURSE</div>
                        </button>
                    </div>
                    <div class="mx-8" style="color: grey">OR</div>
                    <div class="my-8">
                        <button
                            mat-raised-button color="primary"
                            [routerLink]="['projects','search']">
                            <div class="py-8">SEARCH PROJECTS</div>
                        </button>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>

