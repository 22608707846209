import {AfterViewInit, Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {Router} from '@angular/router';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import * as _ from 'lodash';
import {environment} from '../../../environments/environment';
import {ThemeService} from '../../services/theme.service';
import {AuthService} from '../../services/auth.service';
import {OrganizationService} from '../../services/organization.service';
import {LoggingService} from '../../services/logging.service';
import {ChipService} from '../../services/chip.service';
import {LoginDialogComponent} from '../dialogs/login-dialog/login-dialog.component';

declare var grecaptcha: any;

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit, AfterViewInit, OnDestroy {
    registerForm: UntypedFormGroup;
    isDarkMode: boolean;
    userType: string;
    organizations: any = [];
    filteredOrganizations: any[] = [];
    disableOrgField = false;
    showChip: boolean;
    googleRecaptchaSiteKey: string;
    private readonly unsubscribeAll: Subject<void>;

    @Output() signupSuccessful = new EventEmitter();
    @Output() registerError = new EventEmitter();
    @Output() registerLoading = new EventEmitter();

    constructor(
        private fb: UntypedFormBuilder,
        public _matDialog: MatDialog,
        private _router: Router,
        private _themeService: ThemeService,
        private _organizationService: OrganizationService,
        private _authService: AuthService,
        private _loggingService: LoggingService,
        private _chipService: ChipService
    ) {
        this.registerForm = this.fb.group({
                userType: ['employer', Validators.required],
                firstName: [null, Validators.required],
                lastName: [null, Validators.required],
                email: [null, [Validators.required, Validators.email]],
                password: ['', [Validators.required, Validators.minLength(8),
                    Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[.,?!@#\\$%^&*~+_-]).{8,}$')]],
                passwordConfirm: ['', Validators.required],
                organization: [null, Validators.required],
                tos: [null, Validators.required],
                projectIdea: [null],
            },
            {
                // Validator function to ensure that the passwordConfirm field matches the
                // password field.
                validators: [confirmPassword]
            });
        this.googleRecaptchaSiteKey = environment.googleRecaptchaSecretKey;
        this._themeService.isDarkMode().subscribe(v => this.isDarkMode = v);
        this.unsubscribeAll = new Subject();
        this.userType = 'employer';
        this.organizations = [];
    }

    async ngOnInit(): Promise<any> {
        try {
            if (this._chipService.flashMessage) {
                this.showChip = true;
            }
            await this.getOrganizations();
            this.getOrganizationFromEmail();
            this.registerForm.get('userType').valueChanges.pipe(takeUntil(this.unsubscribeAll))
                .subscribe(async (type) => {
                    this.userType = type;
                    await this.getOrganizations();
                });

            this.registerForm.get('organization').valueChanges
                .pipe(takeUntil(this.unsubscribeAll))
                .subscribe((v: any) => {
                    this.filteredOrganizations = this.organizationListFilter(v);
                });
        } catch (e) {
            this._loggingService.logError(e, '/src/app/auth/register/register.component.ts', 'ngOnInit', 91);
        }
    }

    ngAfterViewInit(): void {
        try {
            // Render the Google captcha
            if (grecaptcha && _.isFunction(grecaptcha.render)) {
                grecaptcha.render('register-submit-button', { sitekey: this.googleRecaptchaSiteKey });
            }

            // Bind the callback to the function
            window['register'] = this.onSubmit.bind(this);
        } catch (e) {
            this._loggingService.logError(e, '/src/app/auth/register/register.component.ts', 'ngAfterViewInit', 104);
            this._chipService.setChip('auth/captcha-not-verified');
            this.showChip = true;
        }
    }

    async getOrganizations(): Promise<any> {
        try {
            await this._organizationService.getOrganizations();

            this._organizationService.organizations.pipe(takeUntil(this.unsubscribeAll))
                .subscribe(organizations => {
                    try {
                        this.organizations = organizations.filter(org => org.type === 'university' || org.type === 'employer');
                        this.filteredOrganizations = this.organizations;
                    } catch (e) {
                        this._loggingService.logError(e, '/src/app/auth/register/register.component.ts', 'getOrganizations', 72);
                    }
                });
        } catch (e) {
            this._loggingService.logError(e, '/src/app/auth/register/register.component.ts', 'getOrganizations', 124);
        }
    }

    getOrganizationFromEmail(): void {
        this.registerForm.controls['email'].valueChanges
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((v: any) => {
                try {
                    if (v) {
                        let domain: any = null;
                        let organization: any = null;

                        // Auto switch type to university if edu email
                        if (v.endsWith('.edu')) {
                            this.registerForm.controls['userType'].setValue('faculty');
                        }

                        if (v.includes('@')) {
                            domain = v.split('@')[1];
                        } else {
                            this.disableOrgField = false;
                            this.registerForm.controls['organization'].patchValue(null);
                            this.registerForm.controls['organization'].enable();
                        }

                        if (domain) {
                            this.organizations.forEach((org: any) => {
                                const domainArray = domain.split('.');
                                let mainDomain = domainArray[domainArray.length - 2] + '.' + domainArray[domainArray.length - 1];
                                // For domains that end with co.uk, co.aus, co.in, etc.
                                if (domainArray.length >= 3 && domainArray[domainArray.length - 2] === 'co') {
                                    mainDomain = domainArray[domainArray.length - 3] + '.' + domainArray[domainArray.length - 2]
                                        + '.' + domainArray[domainArray.length - 1];
                                }

                                if (org.domains.includes(mainDomain.toLowerCase())) {
                                    organization = org;
                                }
                            });

                            this.registerForm.controls['organization'].patchValue(organization);
                            // Disable organization field after auto select
                            // if (organization) {
                            //     this.registerForm.controls['organization'].disable();
                            // } else {
                            //     this.registerForm.controls['organization'].enable();
                            // }
                            this.disableOrgField = !!organization;
                        }
                    }
                } catch (e) {
                    this._loggingService.logError(e, 'src/app/auth/register/register.component.ts',
                        'getOrganizationFromEmail', 168);
                }
            });
    }

    async onSubmit(captchaToken: string): Promise<void> {
        try {
            if (this.registerForm.valid) {
                this.registerLoading.emit(true);

                await this._authService.verifyCaptcha(captchaToken);

                const formValue = this.registerForm.getRawValue();

                // If Organization already exists
                if (formValue.organization.ref) {
                    const domainArray = formValue.email.toLowerCase().split('@')[1].split('.');
                    let emailDomain = domainArray[domainArray.length - 2] + '.' + domainArray[domainArray.length - 1];
                    // For domains that end with co.uk, co.aus, co.in, etc.
                    if (domainArray.length >= 3 && domainArray[domainArray.length - 2] === 'co') {
                        emailDomain = domainArray[domainArray.length - 3] + '.' + domainArray[domainArray.length - 2]
                            + '.' + domainArray[domainArray.length - 1];
                    }

                    let domainMatch = false;
                    // Check if the email belongs to an existing domain
                    for (const domain of formValue.organization.domains) {
                        if (emailDomain  === domain) {
                            domainMatch = true;
                            break;
                        }
                    }

                    // If email does not belong to an already registered domain of the organization
                    if (domainMatch === false) {
                        // Update the organization and add the new domain
                        await this._organizationService.updateOrganizationDocument(
                                {domains: [emailDomain, ...formValue.organization.domains]}, formValue.organization.id);
                        // Send an alert email for the domain review
                        this._organizationService.sendDomainUpdateAlertEmail(formValue.organization.id, emailDomain).catch(e => e);
                    }
                }

                // Create User account
                await this._authService.register(formValue, formValue.userType);
                // Send account activation/email verification email
                await this._authService.sendEmailVerification(formValue.email);

                // Set registration success chip.
                this._chipService.setChip(
                    'auth/register/success',
                    {email: this.registerForm.get('email').value}
                );
                this.showChip = true;

                this.signupSuccessful.emit(true);
                this.registerForm.reset();
                this.registerLoading.emit(false);

            } else {
                this.registerForm.markAllAsTouched();
            }
        } catch (e) {
            this.registerLoading.emit(false);
            this.signupSuccessful.emit(false);

            this._chipService.setChip(e.code ? e.code : e.message);
            this.showChip = true;
            this.registerError.next(true);


            grecaptcha.reset();
            window['register'] = this.onSubmit.bind(this);

            this._loggingService.logError(e, '/src/app/auth/register/register.component.ts', 'onSubmit', 209);
        }
    }

    private organizationListFilter(value: any): any {
        let filterValue = '';
        if (typeof value === 'string') {
            filterValue = value.toLowerCase();
        } else {
            filterValue = value ? value.name.toLowerCase() : '';
        }

        return this.organizations.filter(
            (org: any) => org.name.toLowerCase().indexOf(filterValue) === 0
        );
    }

    displayFn(value: any): any {
        return value ? value.name : undefined;
    }

    /**
     * Determines whether or not form error exists for the purpose of mat-error on inputs.
     * @param controlName - Name of the form control.
     * @param validatorName - Name of the validator error for the control.
     */
    checkFormError(controlName: string, validatorName: string): boolean | void {
        const control = this.registerForm.get(controlName);

        if (control) {
            return control.hasError(validatorName);
        }
    }

    async openLoginDialog(redirectRoute: string = this._authService.redirectOnLogin): Promise<void> {
        try {
            const dialogRef = this._matDialog.open(LoginDialogComponent, {
                maxWidth: '800px',
                minWidth: '400px',
                maxHeight: '800px',
                minHeight: '400px'
            });
            dialogRef.afterClosed().pipe(takeUntil(this.unsubscribeAll)).subscribe(async v => {
                try {
                    if (v) {
                        const authDoc = await this._authService.getUserAuthDoc();
                        if (authDoc.role === 'student') {
                            this._router.navigate([redirectRoute ? redirectRoute : '/student/dashboard'])
                                .catch(e => {
                                    this._loggingService.logError(e,
                                        '/src/app/auth/register/register.component.ts',
                                        'openLoginDialog', 301);
                                });
                        }
                        else {
                            const settingsDoc = await this._authService.getUserSettingsDoc();

                            if (settingsDoc.firstProjectCreated || settingsDoc.firstCourseCreated) {
                                this._router.navigate([redirectRoute ? redirectRoute : '/app/dashboard'])
                                    .catch(e => {
                                        this._loggingService.logError(e,
                                            '/src/app/auth/register/register.component.ts',
                                            'openLoginDialog', 312);
                                    });
                            }
                            else {
                                if (authDoc.role === 'employer') {
                                    this._router.navigate(['/app/projects/create']).catch(e => {
                                        this._loggingService.logError(e,
                                            '/src/app/auth/register/register.component.ts',
                                            'openLoginDialog', 320);
                                    });
                                }
                                else {
                                    this._router.navigate(['/app/courses/create']).catch(e => {
                                        this._loggingService.logError(e,
                                            '/src/app/auth/register/register.component.ts',
                                            'openLoginDialog', 327);
                                    });
                                }
                            }
                        }
                    }
                } catch (e) {
                    this._loggingService.logError(e, '/src/app/auth/register/register.component.ts',
                        'openLoginDialog', 221);
                }
            });
        } catch (e) {
            this._loggingService.logError(e, '/src/app/auth/register/register.component.ts',
                'openLoginDialog', 225);
        }
    }

    checkTOS(): void {
        this.registerForm.get('tos').setValue(true);
    }

    ngOnDestroy(): void {
        this.unsubscribeAll.next();
        this.unsubscribeAll.complete();
    }

}

export function facultyEmail(control: AbstractControl): any {

    if (!control || !control.get('email')) {
        return;
    }
    const email = control.get('email');

    if (control.get('userType').value !== 'faculty') {
        if (email.errors && email.errors.notFacultyEmail) {
            if (email.errors.badDomain) {
                email.setErrors({badDomain: true});
            } else {
                email.setErrors(null);
            }
        }
    } else {
        if (!email || (email.errors && !email.errors.notFacultyEmail)) {
            return;
        }

        if (email.value === '') {
            return;
        }

        if (email.value.substr(email.value.length - 4) !== '.edu') {
            return email.setErrors({notFacultyEmail: true});
        } else {
            email.setErrors(null);
        }
    }

    if (!email || (email.errors && !email.errors.badDomain)) {
        return;
    }

    if (email.value === '') {
        return;
    }

    let domainCheck = false;
    const domainList = ['gmail.com', 'hotmail.com', 'aol.com', 'msn.com', 'icloud.net', 'live.com', 'att.net', 'comcast.net',
        'protonmail.com', 'yandex.ru', 'mail.ru', 'rambler.ru', 'foxmail.com', 'sina.com', 'qq.com', 'sohu.com', 'zohomail.com',
        'atmail.com', 'mail.com', '1and1.com', '1and1.co.uk'];
    _.forEach(domainList, (domain) => {
        if (email.value.includes(domain)) {
            domainCheck = true;
        }
    });
    if (domainCheck) {
        return email.setErrors({badDomain: true});
    } else {
        return email.setErrors(null);
    }

    return null;
}

export function confirmPassword(control: AbstractControl): any {
    if (!control || !control.get('passwordConfirm')) {
        return;
    }

    const password = control.get('password');
    const passwordConfirm = control.get('passwordConfirm');

    if (!password || !passwordConfirm) {
        return;
    }

    if (passwordConfirm.value === '') {
        return;
    }

    if (password.value !== passwordConfirm.value) {
        return passwordConfirm.setErrors({passwordsNotMatch: true});
    } else {
        return passwordConfirm.setErrors(null);
    }
}

