import {Injectable, OnDestroy, Renderer2, RendererFactory2} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ThemeService implements OnDestroy {
    private renderer: Renderer2;
    private colorTheme!: string;
    private darkMode = new BehaviorSubject<boolean>(false);
    private readonly unsubscribeAll: Subject<void>;

    constructor(rendererFactory: RendererFactory2) {
        // Creates and initializes a custom renderer
        this.renderer = rendererFactory.createRenderer(null, null);
        this.initTheme();
        this.isDarkMode();
        this.unsubscribeAll = new Subject();
    }

    initTheme(): void {
        // on initiate get the color theme from the local storage or set it to the default theme(light-mode)
        this.getColorTheme();
        // main theme change point. Adding a class representing the theme mode
        // to the custom renderer body will change the theme for the app
        this.renderer.addClass(document.body, this.colorTheme);
    }


    update(theme: 'dark-mode' | 'light-mode'): void {
        // when user toggles set the theme using the call back
        this.setColorTheme(theme);
        // check what was the theme class on the document's body before toggle
        const previousColorThemeClass = theme === 'dark-mode' ? 'light-mode' : 'dark-mode';
        // remove the class to the custom renderer
        this.renderer.removeClass(document.body, previousColorThemeClass);
        // add the new theme class to the custom renderer
        this.renderer.addClass(document.body, theme);
        this.isDarkMode();
    }

    isDarkMode(): Subject<boolean> {
        this.darkMode.next(this.colorTheme === 'dark-mode');
        return this.darkMode;
    }


    private setColorTheme(theme: string): void {
        // set the variable to the provided theme parameter
        this.colorTheme = theme;
        // set the color theme into local storage for persisting the user preference for the next session
        localStorage.setItem('user-theme', theme);
    }

    ngOnDestroy(): void {
        this.unsubscribeAll.next();
        this.unsubscribeAll.complete();
    }

    private getColorTheme(): void {
        // retrieve the user theme preference from the local storage
        const userTheme = localStorage.getItem('user-theme');
        if (userTheme) {
            this.colorTheme = userTheme;
        } else {
            // if no theme preference in localhost render light theme
            this.colorTheme = 'light-mode';
        }
    }
}
