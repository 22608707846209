<mat-toolbar id="toolbar" class="mat-elevation-z8" fxLayout="column" fxLayoutAlign="center center">
    <div class="wrapper-container" fxLayout="row" fxLayoutAlign="space-between center">

        <!-- Logo -->
        <div class="min-w-48" fxLayout="column" fxLayoutAlign="center center">
            <a href="/" aria-label="Go to Home Page">
                <!-- Display logo with text for medium and large screens -->
                <img fxHide.xs
                    [src]="'assets/images/logos/student-projects-'+ (isDarkMode ? 'white-logo-white-text.svg' : 'color-logo-color-text.svg')"
                    alt="Student Opportunity Center Logo"
                    width="200px" height="200px"/>

                <!-- Display only logo for small screens -->
                <img fxHide.gt-xs
                    [src]="'assets/images/logos/student-projects-'+ (isDarkMode ? 'white-logo.svg' : 'color-logo.svg')"
                    alt="Student Opportunity Center Logo"
                    width="40px" height="40px"/>
            </a>
        </div>

        <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="16px">
            <!-- Navigation Bar -->
            <div *ngIf="isLoggedIn && role !== 'student'" class="theme-primary-color-text"
                ngClass.lt-sm="text-[10px]" ngClass.sm="text-[12px]" ngClass.gt-sm="text-[14px]"
                fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="16px" fxLayoutGap.lt-sm="4px">

                <div [matMenuTriggerFor]="projectsMenu"
                    class="cursor-pointer" fxLayout="row" fxLayoutAlign="start center">
                    <span class="hide-xxs">PROJECTS</span>
                    <span class="hide-gt-xxs">P..</span>
                    <mat-icon class="scale-50">expand_more</mat-icon>
                </div>
                <div [matMenuTriggerFor]="coursesMenu"
                    class="cursor-pointer" fxLayout="row" fxLayoutAlign="start center">
                    <span class="hide-xxs">COURSES</span>
                    <span class="hide-gt-xxs">C..</span>
                    <mat-icon class="scale-50">expand_more</mat-icon>
                </div>
                <div [matMenuTriggerFor]="matchesMenu"
                    class="cursor-pointer" fxLayout="row" fxLayoutAlign="start center">
                    <span class="hide-xxs">MATCHES</span>
                    <span class="hide-gt-xxs">M..</span>
                    <mat-icon class="scale-50">expand_more</mat-icon>
                </div>

                <!-- Projects Menu -->
                <mat-menu #projectsMenu="matMenu" [overlapTrigger]="false">
                    <button *ngIf="role === 'owner' || role === 'admin' || role === 'faculty'"
                            mat-menu-item [routerLink]="['/','app','projects','search']"
                            class="theme-primary-color-text">
                        SEARCH
                    </button>
                    <button *ngIf="role === 'employer'"
                            mat-menu-item [routerLink]="['/','app','projects','create']"
                            class="theme-primary-color-text">
                        NEW
                    </button>
                    <button *ngIf="role === 'employer'"
                            mat-menu-item [routerLink]="['/','app','projects','list','user']"
                            class="theme-primary-color-text">
                        DRAFTS
                    </button>
                    <button *ngIf="role === 'employer'"
                            mat-menu-item [routerLink]="['/','app','projects','list','user']"
                            class="theme-primary-color-text">
                        PUBLISHED
                    </button>
                </mat-menu>

                <!-- Courses Menu -->
                <mat-menu #coursesMenu="matMenu" [overlapTrigger]="false">
                    <button *ngIf="role === 'employer'"
                            mat-menu-item [routerLink]="['/','app','courses','search']"
                            class="theme-primary-color-text">
                        SEARCH
                    </button>
                    <button *ngIf="role === 'owner' || role === 'admin' || role === 'faculty'"
                            mat-menu-item [routerLink]="['/','app','courses','create']"
                            class="theme-primary-color-text">
                        NEW
                    </button>
                    <button *ngIf="role === 'owner' || role === 'admin' || role === 'faculty'"
                            mat-menu-item [routerLink]="['/','app','courses','list','user']"
                            class="theme-primary-color-text">
                        DRAFTS
                    </button>
                    <button *ngIf="role === 'owner' || role === 'admin' || role === 'faculty'"
                            mat-menu-item [routerLink]="['/','app','courses','list','user']"
                            class="theme-primary-color-text">
                        PUBLISHED
                    </button>
                </mat-menu>

                <!-- Matches Menu -->
                <mat-menu #matchesMenu="matMenu" [overlapTrigger]="false">
                    <button mat-menu-item [routerLink]="['/','app','requests','new']"
                            class="theme-primary-color-text">
                        NEW
                    </button>
                    <button mat-menu-item [routerLink]="['/','app','requests','matched']"
                            class="theme-primary-color-text">
                        MATCHED
                    </button>
                    <button mat-menu-item [routerLink]="['/','app','requests','archived']"
                            class="theme-primary-color-text">
                        ARCHIVED
                    </button>
                </mat-menu>
            </div>

            <div fxLayout="row" fxLayoutAlign="end center">
                <!-- Auth Buttons -->
                <div *ngIf="!isLoggedIn" fxLayout="row" fxLayoutAlign="end end" fxLayoutGap="16px">
                    <button mat-raised-button color="primary" (click)="navigateToAuth()" aria-label="Register">
                        Sign Up
                    </button>
                    <button mat-stroked-button color="primary" (click)="openLoginDialog()" aria-label="Login">
                        Sign In
                    </button>
                    <div fxLayout="column" fxLayoutAlign="end center"
                        aria-label="Menu Button" matTooltip="Click to open menu">
                        <button mat-icon-button [matMenuTriggerFor]="settingsMenu">
                            <mat-icon>expand_more</mat-icon>
                        </button>
                    </div>
                </div>

                <!-- Switch to -->
                <button
                    *ngIf="isLoggedIn && this.role && this.role !== 'employer' &&
                     ((products && products.length > 0) || (claims && claims.pgUserId && pgOrganizationUrl))"
                    mat-button
                    [matMenuTriggerFor]="switchToMenu"
                    #clickMenuTrigger="matMenuTrigger"
                    class="switch-to-button"
                >
                    <span fxLayout="row" fxLayoutAlign="center center" style="gap: 4px;">
                        <img
                            alt="switch to"
                            style="height: 12px; width: 12px;"
                            [ngStyle]="isDarkMode ? {color: '#FFFFFF'} : null"
                            [src]="switchToIconSrc"
                        />
                        <span fxHide.lt-md>Switch to</span>
                    </span>
                </button>

                <!-- Switch to options menu -->
                <mat-menu #switchToMenu="matMenu" [overlapTrigger]="false" xPosition="before">
                    <ng-container>
                        <span
                            mat-menu-item
                            fxShow.xs
                            fxHide.gt-xs
                            disableRipple="true"
                            disabled="true"
                            style="height: min-content; line-height: 1; white-space: normal; padding: 7px 11px 0 11px;
                             display: flex; font-size: 14px;"
                        >
                            Switch to
                        </span>
                    </ng-container>
                    <ng-container>
                        <button
                            *ngIf="claims && claims.pgUserId && pgOrganizationUrl"
                            mat-menu-item
                            style="height: min-content; line-height: 1; white-space: normal; padding: 7px 11px; display: flex;"
                            (click)="onProductSelect({ product: 'peoplegrove' })"
                        >
                            <span fxLayout="column" fxLayoutAlign="center start">
                                <span style="font-size: 14px; line-height: 24px;"
                                      [ngStyle]="isDarkMode ? null : {color: '#2E333B'}">PeopleGrove</span>
                                <span style="font-size: 12px;"
                                      [ngStyle]="isDarkMode ? null : {color: '#696F7A'}">
                                    Connect with alumni for mentorship, explore careers and access support in your field of interest
                                </span>
                            </span>
                        </button>
                    </ng-container>
                    <ng-container *ngFor="let product of products">
                        <button
                            mat-menu-item
                            style="height: min-content; line-height: 1; white-space: normal; padding: 7px 11px; display: flex;"
                            (click)="onProductSelect(product)"
                        >
                            <span fxLayout="column" fxLayoutAlign="center start">
                                <span style="font-size: 14px; line-height: 24px;"
                                      [ngStyle]="isDarkMode ? null : {color: '#2E333B'}">{{product.title}}</span>
                                <span style="font-size: 12px;"
                                      [ngStyle]="isDarkMode ? null : {color: '#696F7A'}">{{product.description}}</span>
                            </span>
                        </button>
                    </ng-container>
                </mat-menu>

                <!-- User Avatar -->
                <div
                    *ngIf="isLoggedIn"
                    class="avatar cursor-pointer"
                    fxLayout="row"
                    fxLayoutAlign="end center"
                    [matMenuTriggerFor]="settingsMenu"
                    #clickMenuTrigger="matMenuTrigger"
                    matTooltip="Click to open menu"
                >
                    <div *ngIf="userDoc.profileImgSrc">
                        <img alt="Profile Picture" class="avatar-profile-picture mr-8 mr-sm-16" style="max-width: 100px; max-height: 100px"
                            [src]="userDoc.profileImgSrc">
                    </div>
                    <div *ngIf="!userDoc.profileImgSrc" class="avatar-outer-circle" aria-label="Avatar">
                        <div [ngClass]="isDarkMode ? 'avatar-inner-circle-dark' : 'avatar-inner-circle-light'">
                            <div class="pl-8 pt-8">
                                <strong>{{currentUser.displayName.split(' ')[0].charAt(0).toUpperCase()
                                + '' + currentUser.displayName.split(' ')[1].charAt(0).toUpperCase()}}</strong>
                            </div>
                        </div>
                    </div>
                    <div fxLayout="column" aria-label="Menu Button">
                        <mat-icon>expand_more</mat-icon>
                    </div>
                </div>

                <!-- Options menu -->
                <mat-menu #settingsMenu="matMenu" [overlapTrigger]="false">
                    <button mat-menu-item *ngIf="isLoggedIn && role !== 'student'" [routerLink]="['/', 'app', 'profile']">
                        <mat-icon>account_circle</mat-icon>
                        <span>Profile</span>
                    </button>
                    <button mat-menu-item (click)="toggleMode(); $event.stopPropagation();">
                        <mat-icon *ngIf="isDarkMode">light_mode</mat-icon>
                        <mat-icon *ngIf="!isDarkMode">dark_mode</mat-icon>
                        <span>Theme</span>
                    </button>
                    <a mat-menu-item target="_blank"
                        href="https://studentoc.zendesk.com/hc/en-us/categories/5182669360147-Projects-Portal">
                        <mat-icon>help_outline</mat-icon>
                        <span>FAQ</span>
                    </a>
                    <a mat-menu-item target="_blank"
                        href="https://docs.google.com/presentation/d/1r7Qn8J65nkFEZo4cqFMbmtI2HSSnQhOTS1ZtPKDQrX0/edit?usp=sharing">
                        <mat-icon>info_outline</mat-icon>
                        <span>About</span>
                    </a>
                    <button mat-menu-item *ngIf="isLoggedIn" (click)="logOut()">
                        <mat-icon>logout</mat-icon>
                        <span>Logout</span>
                    </button>
                </mat-menu>
            </div>
        </div>
    </div>
</mat-toolbar>
