<div simplebar>
    <div fxLayout="row" fxLayoutAlign="end start">
        <button *ngIf="tokenLogin !== true" mat-icon-button (click)="onNoClick()" aria-label="Close">
            <mat-icon matTooltip="Close">close</mat-icon>
        </button>
    </div>
    <div class="mat-typography" fxLayout="column" fxLayoutAlign="center center">
        <div class="image-container">
            <img *ngIf="!isDarkMode" src="assets/images/logos/student-projects-color-logo-color-text.svg" alt="soc-logo"/>
            <img *ngIf="isDarkMode" src="assets/images/logos/student-projects-white-logo-white-text.svg" alt="soc-logo"/>
        </div>

        <mat-progress-bar [mode]="'indeterminate'" *ngIf="loginLoading"></mat-progress-bar>

        <app-login (loginSuccessful)="handleLoginDialog($event)"
                   (loginError)="showChip = true"
                   (loginLoading)="loginLoading = $event">
        </app-login>

        <div>Dont have an account? <a [href]="'/auth'">Sign Up</a></div>

    </div>
</div>
