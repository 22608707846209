import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {DateTime} from 'luxon';
import {Project, ProjectElasticDocument} from '../models/project.model';
import {FileService} from './file.service';
import {LoggingService} from './logging.service';
import {take} from 'rxjs/operators';
import {OrganizationService} from './organization.service';
import {AuthService} from './auth.service';
import * as _ from 'lodash';
import {environment} from '../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AngularFireFunctions} from '@angular/fire/compat/functions';
import { CourseElasticDocument } from '../models/course.model.js';

@Injectable({
    providedIn: 'root'
})
export class ProjectService {

    private _projects: BehaviorSubject<any>;

    constructor(
        private _firestore: AngularFirestore,
        private _fileService: FileService,
        private _loggingService: LoggingService,
        private _organizationService: OrganizationService,
        private _authService: AuthService,
        private _httpClient: HttpClient,
        private _functions: AngularFireFunctions,
    ) {
        this._projects = new BehaviorSubject<any[]>([]);
    }

    // returns the projects list as an observable
    get projects(): Observable<any[]> {
        return this._projects.asObservable();
    }

    // get the list of projects calling firestore
    async getProjects(): Promise<void> {
        try {
            const projectsQuery = await this._firestore.collection('project').ref.get();
            // save the response from firestore to the variable
            this._projects.next(projectsQuery.docs.map((doc) => {
                return {...doc.data() as {}, id: doc.id, ref: doc.ref.path};
            }));
        } catch (e) {
            this._loggingService.logError(e, '/src/app/services/project.service.ts', 'getProjects', 49);
        }
    }

    async setProject(formData: Project, files: File[], isDraft: boolean, projectId?: string, noEmail?: boolean): Promise<any> {
        let id = projectId;
        const filesArray = [];
        const type = projectId ? 'edit' : 'create';
        try {
            const ref = await this._organizationService.ref.pipe(take(1)).toPromise();
            const user = await this._authService.user.pipe(take(1)).toPromise();
            const userId = user.uid;
            let companyLogo;
            if (formData['companyDetails'] && formData['companyDetails']['companyLogo']
                && formData['companyDetails']['companyLogo']['name']) {
                companyLogo = formData['companyDetails']['companyLogo'];
                delete formData['companyDetails']['companyLogo'];
            }

            // Get a list of the admins who were removed
            const removedAdminIds = [];
            if (id) {
                const projectDoc = await this._firestore.collection('project').doc(id).get().toPromise();
                const oldAdminList = projectDoc.get('adminEmails') ? projectDoc.get('adminEmails') : [];
                // iterate through the old admin list and
                // push to the removed admin list if the new admin list doesnt include it
                for (const admin of oldAdminList) {
                    if (!formData['adminEmails'].includes(admin)) {
                        const adminUser = await this._authService.getUserDocByEmail(admin);

                        if (adminUser) {
                            removedAdminIds.push(adminUser.id);
                        }
                    }
                }
            }


            const adminIds = [];
            const adminEmails = [];
            const claimantEmails = [];
            for (const admins of formData['adminEmails']) {
                const adminUser = await this._authService.getUserDocByEmail(admins);
                if (adminUser) {
                    adminIds.push(adminUser.id);
                    adminEmails.push(admins);
                } else {
                    claimantEmails.push(admins);
                }
            }

            formData['adminEmails'] = adminEmails;
            formData['claimantEmails'] = claimantEmails;


            // create the project documents
            if (id) {
                await this._firestore.collection('project').doc(id).set({
                    ...formData,
                    status: isDraft ? 'draft' : 'published',
                    updated: DateTime.utc().toJSDate(),
                    updatedBy: userId
                }, {merge: true});
            } else {
                const projectDoc = await this._firestore.collection('project').add({
                    ...formData,
                    status: isDraft ? 'draft' : 'published',
                    organizationRef: ref,
                    created: DateTime.utc().toJSDate(),
                    createdBy: userId,
                    updated: DateTime.utc().toJSDate(),
                    updatedBy: userId
                });
                // get the id from the doc
                id = projectDoc.id;
            }

            try {
                if (companyLogo) {
                    const fileObject = await this._fileService.upload(`projectsPortal/project/${id}/${companyLogo.name}`, companyLogo);
                    try {
                        // Add file into the document
                        await this._firestore.collection('project').doc(id).update({
                            'companyDetails.companyLogo': fileObject.url,
                        });
                    } catch (e) {
                        this._loggingService.logError(e, '/src/app/services/project.service.ts', 'setProject', 99);
                        throw Error('Error Updating Project Document With Logo');
                    }
                }
            } catch (e) {
                this._loggingService.logError(e, '/src/app/services/project.service.ts', 'setProject', 107);
                if (type === 'create') {
                    await this._firestore.collection('project').doc(id).delete();
                }
                throw Error('Error Uploading Logo');
            }

            try {
                if (files && files.length) {
                    for (const file of files) {
                        if (file['url']) {
                            filesArray.push(file);
                        } else {
                            const fileObject = await this._fileService.upload(`projectsPortal/project/${id}/${file.name}`, file);
                            filesArray.push(fileObject);
                        }
                    }
                }

                // Add file into the document
                await this._firestore.collection('project').doc(id).update({
                    files: filesArray,
                });
            } catch (e) {
                this._loggingService.logError(e, '/src/app/services/project.service.ts', 'setProject', 131);
                if (type === 'create') {
                    await this._firestore.collection('project').doc(id).delete();
                }
                throw Error('Error Updating Project Document With File');
            }

            try {
                const settings = await this._authService.getUserSettingsDoc();

                if (!settings.firstProjectCreated) {
                    await this._authService.updateUserSettingsDocument({firstProjectCreated: true});
                }
            } catch (e) {
                this._loggingService.logError(e, '/src/app/services/project.service.ts', 'setProject', 145);
            }

            // Update matches & requests with new admins
            try {
                const requestsQuery = await this._firestore.collection('requestMatch', requestMatchRef => {
                    return requestMatchRef
                        .where('requestedProjectId', '==', projectId);
                }).get().toPromise();

                const attachedQuery = await this._firestore.collection('requestMatch', requestMatchRef => {
                    return requestMatchRef
                        .where('attachedProjectId', '==', projectId);
                }).get().toPromise();

                const docs = requestsQuery.docs.concat(attachedQuery.docs);

                for (const request of docs) {
                    if (request.get('requestedProjectId')) {
                        let oldAdmins = request.get('receiverUserIds') ? request.get('receiverUserIds') : [];

                        // Push in any new admins found in the new admin list
                        for (const adminId of adminIds) {
                            if (!oldAdmins.includes(adminId)) {
                                oldAdmins.push(adminId);
                            }
                        }

                        // Remove any admins from the list if they are supposed to be removed
                        oldAdmins = _.filter(oldAdmins, (val) => !removedAdminIds.includes(val));

                        await request.ref.update({receiverUserIds: oldAdmins});
                    } else if (request.get('attachedProjectId')) {
                        let oldAdmins = request.get('senderUserIds') ? request.get('senderUserIds') : [];

                        // Push in any new admins found in the new admin list
                        for (const adminId of adminIds) {
                            if (!oldAdmins.includes(adminId)) {
                                oldAdmins.push(adminId);
                            }
                        }

                        // Remove any admins from the list if they are supposed to be removed
                        oldAdmins = _.filter(oldAdmins, (val) => !removedAdminIds.includes(val));

                        await request.ref.update({senderUserIds: oldAdmins});
                    }

                    if (request.get('matchedProjectCourseId')) {
                        const matchDoc = await this._firestore.collection('matchedProjectCourse')
                            .doc(request.get('matchedProjectCourseId')).get().toPromise();
                        let oldAdmins = matchDoc.get('employerUserIds') ? matchDoc.get('employerUserIds') : [];

                        // Push in any new admins found in the new admin list
                        for (const adminId of adminIds) {
                            if (!oldAdmins.includes(adminId)) {
                                oldAdmins.push(adminId);
                            }
                        }

                        // Remove any admins from the list if they are supposed to be removed
                        oldAdmins = _.filter(oldAdmins, (val) => !removedAdminIds.includes(val));

                        await this._firestore.collection('matchedProjectCourse')
                            .doc(request.get('matchedProjectCourseId'))
                            .update({
                                employerUserIds: oldAdmins
                            });
                    }
                }

            } catch (e) {
                this._loggingService.logError(e, '/src/app/services/project.service.ts', 'setProject', 250);
            }

            try {
                if (isDraft !== true && !noEmail) {
                    await this.sendProjectCreatedConfirmationEmail(userId, formData['companyDetails']['name'], id);
                }
            } catch (e) {
                this._loggingService.logError(e, '/src/app/services/project.service.ts', 'setProject', 153);
            }

            // Return project id
            return id;

        } catch (e) {
            this._loggingService.logError(e, '/src/app/services/project.service.ts', 'setProject', 160);
            return Promise.reject(`Project ${type === 'create' ? 'creation' : 'updating'} failed`);
        }
    }

    async getProjectById(id: string): Promise<any> {
        try {
            const project = await this._firestore.collection('project').doc(id).ref.get();
            const projectData = project.data();
            projectData['adminEmails'] = projectData['adminEmails'].concat(
                projectData['claimantEmails'] ? projectData['claimantEmails'] : []);
            return Promise.resolve({...projectData as {}, id: project.id, ref: project.ref.path});
        } catch (e) {
            this._loggingService.logError(e, '/src/app/services/project.service.ts', 'getProjectById', 170);
        }
    }

    async searchProjects(searchString: string,
                         filters: any = {}, sortField: string = 'created',
                         sortOrder: string = 'desc', pageSize: number = 25, cursor: number = 0): Promise<any> {
        try {
            const baseUrl = _.get(environment, 'projectPortalServiceBaseUrl', 'http://localhost:8085');

            const user = await this._authService.user.pipe(take(1)).toPromise();
            let token;
            let ref;
            let userDoc;
            let role;
            if (user) {
                userDoc = await this._authService.getUserDoc();
                token = await this._authService.token().then((idTokenResult) => idTokenResult.token);
                ref = await this._organizationService.ref.pipe(take(1)).toPromise();
                role = await this._authService.role.pipe(take(1)).toPromise();
            }
            const body = {
                search_string: searchString,
                sort_field: sortField ? sortField : 'created',
                sort_order: sortOrder ? sortOrder : 'desc',
                size: pageSize,
                cursor: cursor,
                filters: {
                    organization_id: filters.organizationId ? filters.organizationId : null,
                    target_organization_ids: filters.targetOrganizationIds ? filters.targetOrganizationIds : null,
                    semester: filters.semester ? filters.semester : null,
                    student_education_level: filters.studentEducationLevel ? filters.studentEducationLevel : null,
                    status: filters.status ? filters.status : null,
                    tag: filters.tag ? filters.tag : null
                }
            };

            if (filters.myProjects && userDoc) {
                body['filters']['admin_email'] = [userDoc.email];
            }

            let headers;
            if (token) {
                headers = new HttpHeaders({
                    Authorization: `Bearer ${token}`
                });
            } else {
                headers = new HttpHeaders({
                    'elastic-guard': await this._authService.getJWT()
                });
            }

            const response = await this._httpClient.post(`${baseUrl}/projects/search`, body, {
                headers: headers
            }).pipe(take(1)).toPromise();

            const results = [];
            for (const project of response['projects']) {
                try {
                    const projectDoc = await this._firestore.collection('project').doc(project.doc_id).get().toPromise();

                    if (projectDoc.exists) {
                        results.push({id: project.doc_id, ...new ProjectElasticDocument(project).toProject()});
                    } else {
                        response['count']['value'] -= 1;
                    }
                } catch (e) {
                    this._loggingService.logError(e, '/src/app/services/project.service.ts',
                        'searchProjects', 348);
                    throw (e);
                }
            }

            response['projects'] = _.cloneDeep(results);
            return response;

        } catch (e) {
            this._loggingService.logError(e, '/src/app/services/project.service.ts',
                'searchProjects', 358);
            throw (e);
        }
    }

    /**
     * Deletes a Project based on document id and returns
     * promise resolve if successful
     * promise reject if error
     * @param id id of document to delete
     */
    async deleteProject(id: string): Promise<any> {
        try {
            await this._firestore.collection('project').doc(id).delete();
            return Promise.resolve('Project Deleted Successfully');
        } catch (e) {
            this._loggingService.logError(e, '/src/app/services/project.service.ts', 'deleteProject', 243);
            throw new Error('Project Delete Failed');
        }
    }

    /**
     * Archives a Project based on document id and returns
     * promise resolve if successful
     * promise reject if error
     * @param id id of document to delete
     */
    async archiveProject(id: string): Promise<any> {
        try {
            await this._firestore.collection('project').doc(id).update({status: 'archived'});
            return Promise.resolve('Project Archived Successfully');
        } catch (e) {
            this._loggingService.logError(e, '/src/app/services/project.service.ts', 'archiveProject', 259);
            throw new Error('Project Archive Failed');
        }
    }

    /**
     * Publishes a Project based on document id and returns
     * promise resolve if successful
     * promise reject if error
     * @param id id of document to delete
     */
    async publishProject(id: string): Promise<any> {
        try {
            await this._firestore.collection('project').doc(id).update({status: 'published'});
            return Promise.resolve('Project Published Successfully');
        } catch (e) {
            this._loggingService.logError(e, '/src/app/services/project.service.ts', 'publishProject', 275);
            throw new Error('Project Published Failed');
        }
    }

    /**
     * Lock a Project based on document id and returns
     * promise resolve if successful
     * promise reject if error
     * @param id id of document to lock
     */
    async lockProject(id: string): Promise<any> {
        try {
            const user = await this._authService.user.pipe(take(1)).toPromise();
            await this._firestore.collection('project').doc(id).update({
                isLocked: true,
                lockedOn: DateTime.utc().toJSDate(),
                lockedBy: user.uid
            });
            return Promise.resolve('Project Locked Successfully');
        } catch (e) {
            this._loggingService.logError(e, '/src/app/services/project.service.ts', 'lockProject', 301);
            throw new Error('Project Locked Failed');
        }
    }

    /**
     * Unlock a Project based on document id and returns
     * promise resolve if successful
     * promise reject if error
     * @param id id of document to lock
     */
    async unlockProject(id: string): Promise<any> {
        try {
            await this._firestore.collection('project').doc(id).update({
                isLocked: false,
                lockedOn: null,
                lockedBy: null
            });
            return Promise.resolve('Project Unlocked Successfully');
        } catch (e) {
            this._loggingService.logError(e, '/src/app/services/project.service.ts', 'unlockProject', 322);
            throw new Error('Project Unlocked Failed');
        }
    }

    /**
     * Save institution preferences as default and returns
     * promise resolve if successful
     * promise reject if error
     * @param data preference data
     */
    async saveAsDefaultInstitution(data: any): Promise<any> {
        try {
            const user = await this._authService.user.pipe(take(1)).toPromise();
            await this._firestore.collection('userSettings').doc(user.uid)
                .update({ppInstitutionPreferences: data});
            return Promise.resolve('Preferences Saved Successfully');
        } catch (e) {
            this._loggingService.logError(e, '/src/app/services/project.service.ts', 'saveAsDefaultInstitution', 293);
            throw new Error('Institution Preferences Saved Failed');
        }
    }

    /**
     * Save student preferences as default and returns
     * promise resolve if successful
     * promise reject if error
     * @param data preference data
     */
    async saveAsDefaultStudent(data: any): Promise<any> {
        try {
            const user = await this._authService.user.pipe(take(1)).toPromise();
            await this._firestore.collection('userSettings').doc(user.uid)
                .update({ppStudentPreferences: data});
            return Promise.resolve('Preferences Saved Successfully');
        } catch (e) {
            this._loggingService.logError(e, '/src/app/services/project.service.ts', 'saveAsDefaultStudent', 311);
            throw new Error('Student Preferences Saved Failed');
        }
    }

    /**
     * Get Default Preferences
     * returns default preferences object
     * promise reject if error
     * @param data preference data
     */
    async getDefaultPreferences(): Promise<any> {
        try {
            const user = await this._authService.user.pipe(take(1)).toPromise();
            const userSettings = await this._firestore.collection('userSettings').doc(user.uid).ref.get();


            return {
                ppStudentPreferences: userSettings.get('ppStudentPreferences'),
                ppInstitutionPreferences: userSettings.get('ppInstitutionPreferences')
            };
        } catch (e) {
            this._loggingService.logError(e, '/src/app/services/project.service.ts', 'getDefaultPreferences', 333);
            throw new Error('Preferences Retrieval Failed');
        }
    }

    async sendProjectCreatedConfirmationEmail(recipientId: string, organizationName: string, docId: string): Promise<any> {
        try {
            return await this._functions.httpsCallable('projectsPortal-sendCourseProjectCreatedConfirmationEmail')({
                recipientId: recipientId,
                organizationName: organizationName,
                type: 'Project',
                docId: docId
            }).toPromise();
        } catch (e) {
            this._loggingService.logError(e, 'src/app/services/project.service.ts',
                'sendProjectCreatedConfirmationEmail', 349);
            throw Error('Operation Failed: Send Project Created Confirmation Email');
        }
    }
}
