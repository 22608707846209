<div class="register-form" fxLayout="column" fxLayoutAlign="center stretch" fxLayoutAlign.lt-sm="start stretch">
    <app-chip [showChip]="showChip"></app-chip>

    <form fxLayout="column" fxLayoutAlign="center stretch" [formGroup]="registerForm">
        <!-- Select User type -->
        <div class="radio-buttons" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
            <span id="example-radio-group-label">I am an</span>
            <mat-radio-group color="accent"
                             aria-labelledby="example-radio-group-label"
                             class="example-radio-group"
                             formControlName="userType">
                <mat-radio-button value="employer">Employer</mat-radio-button>
                <mat-radio-button value="faculty">Faculty</mat-radio-button>
            </mat-radio-group>
        </div>

        <!-- First Name -->
        <mat-form-field appearance="outline">
            <input matInput
                   type="text"
                   placeholder="First Name"
                   formControlName="firstName"
                   autocomplete>
            <mat-error *ngIf="registerForm.get('firstName')?.hasError('required')">
                First Name is a required field.
            </mat-error>
        </mat-form-field>

        <!-- Last Name -->
        <mat-form-field appearance="outline">
            <input matInput
                   type="text"
                   placeholder="Last Name"
                   formControlName="lastName"
                   autocomplete>
            <mat-error *ngIf="registerForm.get('lastName')?.hasError('required')">
                Last Name is a required field.
            </mat-error>
        </mat-form-field>

        <!-- Email -->
        <mat-form-field appearance="outline">
            <input matInput
                   type="email"
                   placeholder="Email"
                   formControlName="email"
                   autocomplete>
            <mat-error *ngIf="registerForm.get('email')?.hasError('required')">
                Email is a required field.
            </mat-error>
            <mat-error *ngIf="registerForm.get('email')?.hasError('email') || registerForm.get('email')?.hasError('pattern')">
                Make sure you have typed a valid email.
            </mat-error>

            <mat-error *ngIf="registerForm.get('email')?.hasError('notFacultyEmail')">
                Please use your institution email address.
            </mat-error>
            <mat-error *ngIf="registerForm.get('email')?.hasError('badDomain')">
                Please use your institution email address.
            </mat-error>
        </mat-form-field>

        <!-- Password -->
        <mat-form-field appearance="outline">
            <input matInput
                   type="password"
                   placeholder="Password"
                   formControlName="password"
                   maxlength="50"
                   aria-label="Password"
                   autocomplete>
            <mat-error *ngIf="checkFormError('password', 'required')">
                Password is required
            </mat-error>
            <mat-error
                *ngIf="!checkFormError('password', 'required')
                        && checkFormError('password', 'minlength')">
                Please provide at least 8 characters
            </mat-error>
            <mat-error
                *ngIf="!checkFormError('password', 'required')
                        && !checkFormError('password', 'minlength')
                        && checkFormError('password', 'pattern')">
                Password must have at least 1 upper case, 1 lower case, 1 number and 1 special character
                (<span class="text-italic">.,?!@#$%^&*~+_-</span>)
            </mat-error>
        </mat-form-field>

        <!-- Confirm Password -->
        <mat-form-field appearance="outline">
            <input matInput
                   type="password"
                   placeholder="Confirm Password"
                   formControlName="passwordConfirm"
                   maxlength="50"
                   aria-label="Confirm Password"
                   autocomplete>
            <mat-error *ngIf="checkFormError('passwordConfirm', 'required')">
                Password confirmation is required
            </mat-error>
            <mat-error *ngIf="checkFormError('passwordConfirm', 'passwordsNotMatch')">
                Passwords must match
            </mat-error>
        </mat-form-field>

        <!-- Organization List -->
        <mat-form-field appearance="outline">
            <input matInput
                   type="text"
                   placeholder="Organization Name"
                   formControlName="organization"
                   [matAutocomplete]="auto">
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                <div simplebar>
                    <mat-option
                        *ngFor="let organization of filteredOrganizations"
                        [value]="organization">
                        {{organization.name}}
                    </mat-option>
                </div>
            </mat-autocomplete>
            <mat-error *ngIf="registerForm.get('organization')?.hasError('required')">
                Organization is a required field.
            </mat-error>
        </mat-form-field>

        <div class="register-button-container" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="32px">
            <!-- Submit -->
            <button mat-raised-button
                    id="register-submit-button"
                    class="g-recaptcha btn-block"
                    type="button"
                    color="primary"
                    [attr.data-sitekey]="googleRecaptchaSiteKey"
                    data-callback="register"
                    [disabled]="registerForm.invalid || (registerLoading | async)">
                Sign Up
            </button>
            <!-- TOS -->
            <mat-checkbox required formControlName="tos" aria-label="Terms & Conditions">
                <a target="_blank" href="https://studentopportunitycenter.com/privacy-policy/" (click)="checkTOS()">
                    <div class="pt-4">Terms & Conditions</div>
                </a>
            </mat-checkbox>
        </div>

        <!-- Login -->
<!--        <div class="my-16" fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="4px" fxFlexFill>-->
<!--            <span>Have an account?</span>-->
<!--            <a class="cursor-pointer" (click)="openLoginDialog()">Sign In</a>-->
<!--        </div>-->

    </form>
</div>

