import {Directive, ElementRef, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {Subject} from 'rxjs';
import {LoggingService} from '../services/logging.service';


/*
    Add the actual simplebar scrollbar directive (data-simplebar)
    and sets the style with height: 100% for the simplebar to work
 */
@Directive({
    selector: '[simplebar]'
})
export class SimplebarDirective implements OnInit, OnDestroy {
    _unsubscribeAll: Subject<void>;

    constructor(
        private _element: ElementRef,
        private _renderer: Renderer2,
        private _loggingService: LoggingService,
    ) {
        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void {
        try {
            // Bug: Need height set to 100% for simplebar to work
            this._renderer.setAttribute(this._element.nativeElement, 'style', 'height:100%');
            // Add the actual simplebar directive to the element
            this._renderer.setAttribute(this._element.nativeElement, 'data-simplebar', null);

        } catch (e) {
            this._loggingService.logError(e, 'src/app/directive/simplebar.directive.ts', 'ngOnInit', 33);
        }
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

}
