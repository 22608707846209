import {AfterViewInit, Component, EventEmitter, OnDestroy, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Router, RouterStateSnapshot} from '@angular/router';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {ThemeService} from '../../services/theme.service';
import {AuthService} from '../../services/auth.service';
import {LoggingService} from '../../services/logging.service';
import {ChipService} from '../../services/chip.service';
import * as _ from 'lodash';

declare var grecaptcha: any;

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements AfterViewInit, OnDestroy {
    loginForm: UntypedFormGroup;
    isDarkMode: boolean;
    showChip: boolean;
    googleRecaptchaSiteKey: string;
    private readonly unsubscribeAll: Subject<void>;

    @Output() loginSuccessful = new EventEmitter();
    @Output() loginError = new EventEmitter();
    @Output() loginLoading = new EventEmitter();

    constructor(
        private _fb: UntypedFormBuilder,
        private _themeService: ThemeService,
        private _authService: AuthService,
        private _router: Router,
        private _loggingService: LoggingService,
        private _chipService: ChipService
    ) {
        this.loginForm = this._fb.group({
            email: [null, [Validators.required, Validators.email]],
            password: [null, Validators.required],
            remember: [true]
        });

        this.googleRecaptchaSiteKey = environment.googleRecaptchaSecretKey;

        this.unsubscribeAll = new Subject();

        this._themeService.isDarkMode().pipe(takeUntil(this.unsubscribeAll))
            .subscribe(v => {
                this.isDarkMode = v;
            });
    }

    async ngAfterViewInit(): Promise<void> {
        try {
            // Bind the callback to the function
            window['login'] = this.onLogin.bind(this);

            const snapshot: RouterStateSnapshot = this._router.routerState.snapshot;
            const customToken = snapshot.root.queryParamMap.get('token');

            try {
                if (customToken) {
                    this.loginLoading.emit(true);
                    await this._authService.ssoLogin(customToken);
                    this.loginLoading.emit(false);
                    this.loginSuccessful.emit(true);
                    return;
                }
            } catch (e) {
                this.loginLoading.emit(false);
                this._loggingService.logError(e, 'src/app/auth/login/login.component.ts', 'ngAfterViewInit', 68);
                this._chipService.setChip('auth/invalid-custom-token');
                this.showChip = true;
            }

            // Render the Google captcha
            if (grecaptcha && _.isFunction(grecaptcha.render)) {
                grecaptcha.render('login-submit-button', { sitekey: this.googleRecaptchaSiteKey });
            }
        } catch (e) {
            this._loggingService.logError(e, 'src/app/auth/login/login.component.ts', 'ngAfterViewInit', 80);
            this._chipService.setChip('auth/captcha-not-verified');
            this.showChip = true;
        }
    }

    async onLogin(captchaToken: string): Promise<any> {
        try {
            this.loginLoading.emit(true);

            await this._authService.verifyCaptcha(captchaToken);

            const {email, password, remember} = this.loginForm.getRawValue();
            await this._authService.login(email.toLowerCase(), password, remember);

            this.loginLoading.emit(false);
            this.loginSuccessful.emit(true);

        } catch (e) {
            this.loginLoading.emit(false);
            this.loginSuccessful.emit(false);

            this._chipService.setChip(e.code ? e.code : e.message);
            this.showChip = true;
            this.loginError.next(true);

            grecaptcha.reset();
            window['login'] = this.onLogin.bind(this);

            this._loggingService.logError(e, '/src/app/auth/login/login.component.ts', 'onLogin', 112);
        }
    }

    ngOnDestroy(): void {
        this.unsubscribeAll.next();
        this.unsubscribeAll.complete();
    }
}
