import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {AngularFireAuth} from '@angular/fire/compat/auth';
import {environment} from '../../environments/environment';
import * as _ from 'lodash';
import firebase from 'firebase/compat/app';

@Injectable({
    providedIn: 'root'
})
export class LoggingService {
    private user = 'Anonymous';

    constructor(
        private _httpClient: HttpClient,
        private _fireAuth: AngularFireAuth
    ) {
        _fireAuth.currentUser
            .then((currentUser: firebase.User | null) => {
                if (currentUser) {
                    this.user = currentUser.uid;
                }
                else {
                    this.user = 'Anonymous';
                }
            })
            .catch(e => {
                this.user = 'Anonymous';
            });
    }

    /**
     * Sends an error report to StackDriver Error Reporting.
     * Make sure that your environment.ts file has all of the appropriate variables needed.
     */
    logError(error: any, filePath?: string, functionName?: string, lineNumber?: number): void {
        const stackDriverErrorReportingKey = _.get(environment, 'stackDriverErrorReportingKey', null);
        const logging = _.get(environment, 'logging', null);
        const version = _.get(environment, 'version', 'undefined');
        const projectId = _.get(environment, 'firebaseConfig.projectId', 'none');

        // Only send to StackDriving Error Reporting if the api key exists and logging is enabled.
        // Else log it in browser console
        if (logging && stackDriverErrorReportingKey) {
            let errorMessage = '';

            if (error instanceof Error) {
                errorMessage = `${error.message}`;
            }
            else if (typeof error === 'string') {
                errorMessage = error;
            }

            const reportedErrorEvent = {
                serviceContext: {
                    service: 'projects-portal-app',
                    version: version
                },
                message: errorMessage,
                context: {
                    user: this.user,
                    reportLocation: {
                        filePath: filePath ? filePath : null,
                        functionName: functionName ? functionName : null,
                        lineNumber: lineNumber ? lineNumber : null
                    }
                }
            };

            const baseUrl = 'https://clouderrorreporting.googleapis.com/v1beta1/projects/';

            this._httpClient.post(`${baseUrl}${projectId}/events:report?key=${stackDriverErrorReportingKey}`, reportedErrorEvent)
                .toPromise().catch((e) => {
                    console.error('Log Reporting Error');
                    console.error(e);
            });
        } else {
            console.error(error);
        }
    }
}
