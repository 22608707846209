import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {BehaviorSubject, Observable} from 'rxjs';
import {Course, CourseElasticDocument} from '../models/course.model';
import {DateTime} from 'luxon';
import {FileService} from './file.service';
import {LoggingService} from './logging.service';
import {OrganizationService} from './organization.service';
import {take} from 'rxjs/operators';
import {AuthService} from './auth.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import * as _ from 'lodash';
import {environment} from '../../environments/environment';
import {AngularFireFunctions} from '@angular/fire/compat/functions';

@Injectable({
    providedIn: 'root'
})
export class CourseService {
    private _courses: BehaviorSubject<any>;

    constructor(
        private _firestore: AngularFirestore,
        private _fileService: FileService,
        private _loggingService: LoggingService,
        private _organizationService: OrganizationService,
        private _authService: AuthService,
        private _httpClient: HttpClient,
        private _functions: AngularFireFunctions,
    ) {
        this._courses = new BehaviorSubject<any[]>([]);
    }

    // returns the courses list as an observable
    get courses(): Observable<any[]> {
        return this._courses.asObservable();
    }

    // get the list of courses calling firestore
    async getCourses(): Promise<void> {
        try {
            const coursesQuery = await this._firestore.collection('course').ref
                .where('organizationRef', '==', this._organizationService.ref)
                .get();

            // save the response from firestore to the variable
            this._courses.next(coursesQuery.docs.map((doc) => {
                return {...doc.data() as {}, id: doc.id, ref: doc.ref.path};
            }));
        } catch (e) {
            this._loggingService.logError(e, '/src/app/services/course.service.ts', 'getCourses', 51);
        }
    }

    async setCourse(formData: Course, files: File[], isDraft: boolean, courseId?: string, noEmail?: boolean): Promise<any> {
        let id = courseId;
        const filesArray = [];
        const type = courseId ? 'edit' : 'create';
        try {
            const ref = await this._organizationService.ref.pipe(take(1)).toPromise();
            const user = await this._authService.user.pipe(take(1)).toPromise();
            const userId = user.uid;
            let universityLogo;
            if (formData['facultyDetails'] && formData['facultyDetails']['universityLogo'] && formData['facultyDetails']['universityLogo']['name']) {
                universityLogo = formData['facultyDetails']['universityLogo'];
                delete formData['facultyDetails']['universityLogo'];
            }

            // Get a list of the admins who were removed
            const removedAdminIds = [];
            if (id) {
                const courseDoc = await this._firestore.collection('course').doc(id).get().toPromise();
                const oldAdminList = courseDoc.get('adminEmails') ? courseDoc.get('adminEmails') : [];
                // iterate through the old admin list and
                // push to the removed admin list if the new admin list doesnt include it
                for (const admin of oldAdminList) {
                    if (!formData['adminEmails'].includes(admin)) {
                        const adminUser = await this._authService.getUserDocByEmail(admin);

                        if (adminUser) {
                            removedAdminIds.push(adminUser.id);
                        }
                    }
                }
            }

            const adminIds = [];
            const adminEmails = [];
            const claimantEmails = [];
            for (const admins of formData['adminEmails']) {
                const adminUser = await this._authService.getUserDocByEmail(admins);
                if (adminUser) {
                    adminIds.push(adminUser.id);
                    adminEmails.push(admins);
                } else {
                    claimantEmails.push(admins);
                }
            }

            formData['adminEmails'] = adminEmails;
            formData['claimantEmails'] = claimantEmails;


            if (id) {
                // create the course documents
                await this._firestore.collection('course').doc(id).set({
                    ...formData,
                    status: isDraft ? 'draft' : 'published',
                    updated: DateTime.utc().toJSDate(),
                    updatedBy: userId
                }, {merge: true});

            } else {
                const courseDoc = await this._firestore.collection('course').add({
                    ...formData,
                    status: isDraft ? 'draft' : 'published',
                    organizationRef: ref,
                    created: DateTime.utc().toJSDate(),
                    createdBy: userId,
                    updated: DateTime.utc().toJSDate(),
                    updatedBy: userId
                });
                // get the id from the doc
                id = courseDoc.id;

            }

            try {
                if (universityLogo) {
                    const fileObject = await this._fileService.upload(`projectsPortal/course/${id}/${universityLogo.name}`, universityLogo);
                    try {
                        // Add file into the document
                        await this._firestore.collection('course').doc(id).update({
                            'facultyDetails.universityLogo': fileObject.url,
                        });
                    } catch (e) {
                        this._loggingService.logError(e, '/src/app/services/course.service.ts', 'setCourse', 102);
                        throw Error('Error Updating Course Document With Logo');
                    }
                }
            } catch (e) {
                this._loggingService.logError(e, '/src/app/services/course.service.ts', 'setCourse', 107);
                if (type === 'create') {
                    await this._firestore.collection('course').doc(id).delete();
                }
                throw Error('Error Uploading Logo');
            }

            try {
                if (files && files.length) {
                    for (const file of files) {
                        if (file['url']) {
                            filesArray.push(file);
                        } else {
                            const fileObject = await this._fileService.upload(`projectsPortal/course/${id}/${file.name}`, file);
                            filesArray.push(fileObject);
                        }
                    }
                }

                // Add file into the document
                await this._firestore.collection('course').doc(id).set({
                    files: filesArray,
                    id: id
                }, {merge: true});

            } catch (e) {
                this._loggingService.logError(e, '/src/app/services/course.service.ts', 'setCourse', 119);
                if (type === 'create') {
                    await this._firestore.collection('course').doc(id).delete();
                }
                throw Error('Error Updating Course Document With Files');
            }

            try {
                const settings = await this._authService.getUserSettingsDoc();

                if (!settings.firstCourseCreated) {
                    await this._authService.updateUserSettingsDocument({firstCourseCreated: true});
                }
            } catch (e) {
                this._loggingService.logError(e, '/src/app/services/course.service.ts', 'setCourse', 147);
            }

            // Update matches & requests with new admins
            try {
                const requestsQuery = await this._firestore.collection('requestMatch', requestMatchRef => {
                    return requestMatchRef
                        .where('requestedCourseId', '==', courseId);
                }).get().toPromise();

                const attachedQuery = await this._firestore.collection('requestMatch', requestMatchRef => {
                    return requestMatchRef
                        .where('attachedCourseId', '==', courseId);
                }).get().toPromise();

                const docs = requestsQuery.docs.concat(attachedQuery.docs);

                for (const request of docs) {
                    if (request.get('requestedCourseId')) {
                        let oldAdmins = request.get('receiverUserIds') ? request.get('receiverUserIds') : [];

                        // Push in any new admins found in the new admin list
                        for (const adminId of adminIds) {
                            if (!oldAdmins.includes(adminId)) {
                                oldAdmins.push(adminId);
                            }
                        }

                        // Remove any admins from the list if they are supposed to be removed
                        oldAdmins = _.filter(oldAdmins, (val) => !removedAdminIds.includes(val));

                        await request.ref.update({receiverUserIds: oldAdmins});
                    } else if (request.get('attachedCourseId')) {
                        let oldAdmins = request.get('senderUserIds') ? request.get('senderUserIds') : [];

                        // Push in any new admins found in the new admin list
                        for (const adminId of adminIds) {
                            if (!oldAdmins.includes(adminId)) {
                                oldAdmins.push(adminId);
                            }
                        }

                        // Remove any admins from the list if they are supposed to be removed
                        oldAdmins = _.filter(oldAdmins, (val) => !removedAdminIds.includes(val));

                        await request.ref.update({senderUserIds: oldAdmins});
                    }

                    if (request.get('matchedProjectCourseId')) {
                        const matchDoc = await this._firestore.collection('matchedProjectCourse')
                            .doc(request.get('matchedProjectCourseId')).get().toPromise();
                        let oldAdmins = matchDoc.get('facultyUserIds') ? matchDoc.get('facultyUserIds') : [];

                        // Push in any new admins found in the new admin list
                        for (const adminId of adminIds) {
                            if (!oldAdmins.includes(adminId)) {
                                oldAdmins.push(adminId);
                            }
                        }

                        // Remove any admins from the list if they are supposed to be removed
                        oldAdmins = _.filter(oldAdmins, (val) => !removedAdminIds.includes(val));

                        await this._firestore.collection('matchedProjectCourse')
                            .doc(request.get('matchedProjectCourseId'))
                            .update({
                                facultyUserIds: oldAdmins
                            });
                    }
                }

            } catch (e) {
                this._loggingService.logError(e, '/src/app/services/course.service.ts', 'setCourse', 155);
            }

            try {
                if (isDraft !== true && !noEmail) {
                    await this.sendCourseProjectPublishedConfirmationEmail(userId, formData['facultyDetails']['university'], id);
                }
            } catch (e) {
                this._loggingService.logError(e, '/src/app/services/course.service.ts', 'setCourse', 155);
            }

            // Return Course Id
            return id;
        } catch (e) {
            this._loggingService.logError(e, '/src/app/services/course.service.ts', 'setCourse', 161);
            return Promise.reject(`Course ${type === 'create' ? 'creation' : 'updating'} failed`);
        }
    }

    async getCourseById(id: string): Promise<any> {
        try {
            const course = await this._firestore.collection('course').doc(id).ref.get();
            const courseData = course.data();
            courseData['adminEmails'] = courseData['adminEmails'].concat(
                courseData['claimantEmails'] ? courseData['claimantEmails'] : []);
            return Promise.resolve({...courseData as {}, id: course.id, ref: course.ref.path});
        } catch (e) {
            this._loggingService.logError(e, '/src/app/services/course.service.ts', 'getCourseById', 171);
        }
    }

    async searchCourses(searchString: string,
                        filters: any = {}, sortField: string = 'created',
                        sortOrder: string = 'desc', pageSize: number = 25, cursor: number = 0): Promise<any> {
        try {
            const baseUrl = _.get(environment, 'projectPortalServiceBaseUrl', 'http://localhost:8085');
            const user = await this._authService.user.pipe(take(1)).toPromise();
            let token;
            let ref;
            let userDoc;
            let role;
            if (user) {
                userDoc = await this._authService.getUserDoc();
                token = await this._authService.token().then((idTokenResult) => idTokenResult.token);
                ref = await this._organizationService.ref.pipe(take(1)).toPromise();
                role = await this._authService.role.pipe(take(1)).toPromise();
            }
            const body = {
                search_string: searchString,
                sort_field: sortField,
                sort_order: sortOrder,
                size: pageSize,
                cursor: cursor,
                filters: {
                    organization_id: filters.organizationId ? filters.organizationId : null,
                    status: filters.status ? filters.status : null,
                    semester: filters['semester'] ? filters['semester'] : null,
                    class_size: filters['classSize'] ? filters['classSize'] : null,
                    tag: filters['tag'] ? filters['tag'] : null,
                    student_education_level: filters['studentEducationLevel'] ? filters['studentEducationLevel'] : null,
                    team_size: filters['teamSize'] ? filters['teamSize'] : null,
                    student_hour_per_course: filters['studentHourPerCourse'] ? filters['studentHourPerCourse'] : null,
                    preferred_project_length: filters['preferredProjectLength'] ? filters['preferredProjectLength'] : null
                }
            };

            if (filters.myCourses && userDoc) {
                body['filters']['admin_email'] = [userDoc.email];
            }

            let headers;
            if (token) {
                headers = new HttpHeaders({
                    Authorization: `Bearer ${token}`
                });
            } else {
                headers = new HttpHeaders({
                    'elastic-guard': await this._authService.getJWT()
                });
            }

            const response = await this._httpClient.post(`${baseUrl}/courses/search`, body, {
                headers: headers
            }).pipe(take(1)).toPromise();

            const results = [];
            for (const course of response['courses']) {
                try {
                    const courseDoc = await this._firestore.collection('course').doc(course.doc_id).get().toPromise();

                    if (courseDoc.exists) {
                        results.push({id: course.doc_id, ...new CourseElasticDocument(course).toCourse()});
                    } else {
                        response['count']['value'] -= 1;
                    }
                } catch (e) {
                    this._loggingService.logError(e, '/src/app/services/course.service.ts',
                        'searchCourses', 351);
                    throw (e);
                }
            }

            response['courses'] = _.cloneDeep(results);
            return response;

        } catch (e) {
            this._loggingService.logError(e, '/src/app/services/course.service.ts',
                'searchCourses', 359);
            throw (e);
        }
    }

    /**
     * Deletes a Course based on document id and returns
     * promise resolve if successful
     * promise reject if error
     * @param id id of document to delete
     */
    async deleteCourse(id: string): Promise<any> {
        try {
            await this._firestore.collection('course').doc(id).delete();
            return Promise.resolve('Course Deleted Successfully');
        } catch (e) {
            this._loggingService.logError(e, '/src/app/services/course.service.ts', 'deleteCourse', 248);
            throw new Error('Course Delete Failed');
        }
    }

    /**
     * Archives a Course based on document id and returns
     * promise resolve if successful
     * promise reject if error
     * @param id id of document to delete
     */
    async archiveCourse(id: string): Promise<any> {
        try {
            await this._firestore.collection('course').doc(id).update({status: 'archived'});
            return Promise.resolve('Course Archived Successfully');
        } catch (e) {
            this._loggingService.logError(e, '/src/app/services/course.service.ts', 'archiveCourse', 264);
            throw new Error('Course Archive Failed');
        }
    }

    /**
     * Publishes a Course based on document id and returns
     * promise resolve if successful
     * promise reject if error
     * @param id id of document to delete
     */
    async publishCourse(id: string): Promise<any> {
        try {
            await this._firestore.collection('course').doc(id).update({status: 'published'});
            return Promise.resolve('Course Published Successfully');
        } catch (e) {
            this._loggingService.logError(e, '/src/app/services/course.service.ts', 'publishCourse', 280);
            throw new Error('Course Publish Failed');
        }
    }

    /**
     * Lock a Course based on document id and returns
     * promise resolve if successful
     * promise reject if error
     * @param id id of document to lock
     */
    async lockCourse(id: string): Promise<any> {
        try {
            const user = await this._authService.user.pipe(take(1)).toPromise();
            await this._firestore.collection('course').doc(id).update({
                isLocked: true,
                lockedOn: DateTime.utc().toJSDate(),
                lockedBy: user.uid
            });
            return Promise.resolve('Course Locked Successfully');
        } catch (e) {
            this._loggingService.logError(e, '/src/app/services/course.service.ts', 'lockCourse', 301);
            throw new Error('Course Locked Failed');
        }
    }

    /**
     * Unlock a Course based on document id and returns
     * promise resolve if successful
     * promise reject if error
     * @param id id of document to lock
     */
    async unlockCourse(id: string): Promise<any> {
        try {
            await this._firestore.collection('course').doc(id).update({
                isLocked: false,
                lockedOn: null,
                lockedBy: null
            });
            return Promise.resolve('Course Unlocked Successfully');
        } catch (e) {
            this._loggingService.logError(e, '/src/app/services/course.service.ts', 'unlockCourse', 322);
            throw new Error('Course Unlocked Failed');
        }
    }

    /**
     * Save company preferences as default and returns
     * promise resolve if successful
     * promise reject if error
     * @param data preference data
     */
    async saveAsDefaultCompany(data: any): Promise<any> {
        try {
            const user = await this._authService.user.pipe(take(1)).toPromise();
            await this._firestore.collection('userSettings').doc(user.uid)
                .update({ppCompanyPreferences: data});
            return Promise.resolve('Preferences Saved Successfully');
        } catch (e) {
            this._loggingService.logError(e, '/src/app/services/course.service.ts', 'saveAsDefaultCompany', 298);
            throw new Error('Preferences Saved Failed');
        }
    }


    /**
     * Save student preferences as default and returns
     * promise resolve if successful
     * promise reject if error
     * @param data preference data
     */
    async saveAsDefaultStudent(data: any): Promise<any> {
        try {
            const user = await this._authService.user.pipe(take(1)).toPromise();
            await this._firestore.collection('userSettings').doc(user.uid)
                .update({ppStudentPreferences: data});
            return Promise.resolve('Preferences Saved Successfully');
        } catch (e) {
            this._loggingService.logError(e, '/src/app/services/course.service.ts', 'saveAsDefaultStudent', 317);
            throw new Error('Preferences Saved Failed');
        }
    }

    /**
     * Get Default Preferences
     * returns default preferences object
     * promise reject if error
     * @param data preference data
     */
    async getDefaultPreferences(): Promise<any> {
        try {
            const user = await this._authService.user.pipe(take(1)).toPromise();
            const userSettings = await this._firestore.collection('userSettings').doc(user.uid).ref.get();


            return {
                ppStudentPreferences: userSettings.get('ppStudentPreferences'),
                ppCompanyPreferences: userSettings.get('ppCompanyPreferences')
            };
        } catch (e) {
            this._loggingService.logError(e, '/src/app/services/course.service.ts', 'getDefaultPreferences', 339);
            throw new Error('Preferences Retrieval Failed');
        }
    }

    async sendCourseProjectPublishedConfirmationEmail(recipientId: string, organizationName: string, docId: string): Promise<any> {
        try {
            return await this._functions.httpsCallable('projectsPortal-sendCourseProjectPublishedConfirmationEmail')({
                recipientId: recipientId,
                organizationName: organizationName,
                type: 'Course',
                docId: docId
            }).toPromise();
        } catch (e) {
            this._loggingService.logError(e, 'src/app/services/course.service.ts',
                'sendCourseCreatedConfirmationEmail', 355);
            throw Error('Operation Failed: Send Course Created Confirmation Email');
        }
    }


}
