import {Injectable} from '@angular/core';
import {AngularFireStorage} from '@angular/fire/compat/storage';
import {FileModel} from '../models/file.model';
import {DateTime} from 'luxon';
import {LoggingService} from './logging.service';

@Injectable({
    providedIn: 'root'
})
export class FileService {

    constructor(
        private _fireStorage: AngularFireStorage,
        private _loggingService: LoggingService,
    ) {
    }

    async upload(path: string, file: any, description?: string, type?: string): Promise<any> {
        const timestamp = DateTime.now().toUnixInteger().toString();
        try {
            if (path === undefined || path === null) {
                throw Error('Invalid file path');
            }

            if (file === undefined || file === null) {
                throw Error('File not available');
            }

            const upload = await this._fireStorage.upload(path, file);
            const downloadUrl = await upload.ref.getDownloadURL();
            const nameSplit = file.name.split('.');
            const extension = nameSplit[nameSplit.length - 1];
            return {
                id: timestamp,
                path,
                name: file.name,
                description: description ? description : 'attachment',
                url: downloadUrl,
                type: type ? type : 'attachment',
                mimeType: file.type,
                extension,
                size: file.size,
            } as FileModel;

        } catch (e) {
            this._loggingService.logError(e, '/src/app/services/file.service.ts', 'upload', 46);
            throw Error('Error Uploading File');
        }
    }
}
