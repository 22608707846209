export const icons8FileNames = [
    'icons8-api.svg',
    'icons8-calendar-clock.svg',
    'icons8-csv.svg',
    'icons8-date-span.svg',
    'icons8-date-span.svg',
    'icons8-error-cloud.svg',
    'icons8-facebook.svg',
    'icons8-gmail.svg',
    'icons8-linkedin.svg',
    'icons8-passport.svg',
    'icons8-twitter.svg',
    'icons8-us-dollar.svg',
    'icons8-google-calendar.svg',
    'icons8-microsoft-outlook.svg'
];
