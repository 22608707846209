import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MaterialModule} from './material/material.module';
import {RouterModule} from '@angular/router';
import {FlexModule, ExtendedModule} from '@angular/flex-layout';
import {DirectivesModule} from '../directives/directives.module';


@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        MaterialModule,
        RouterModule,
        FlexModule,
        ExtendedModule,
        DirectivesModule
    ],
    exports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        MaterialModule,
        RouterModule,
        FlexModule,
        ExtendedModule,
        DirectivesModule
    ]
})
export class SharedModule {
}
