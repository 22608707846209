<div class="login-form" fxLayout="column" fxLayoutAlign="space-around center">
    <app-chip [showChip]="showChip"></app-chip>

    <form fxLayout="column" fxLayoutAlign="center center" [formGroup]="loginForm">
        <!-- Email -->
        <mat-form-field appearance="outline">
            <input matInput type="email" placeholder="Email" formControlName="email" autocomplete><br>
            <mat-error *ngIf="loginForm.get('email')?.hasError('required')">
                Email is a required field.
            </mat-error>
        </mat-form-field>

        <!-- Password -->
        <mat-form-field appearance="outline">
            <input matInput type="password" placeholder="Password" formControlName="password" autocomplete><br>
            <mat-error *ngIf="loginForm.get('password')?.hasError('required')">
                Password is a required field.
            </mat-error>
        </mat-form-field>

        <!-- Remember Me -->
        <mat-checkbox formControlName="remember" class="remember-me" aria-label="Remember Me">
            Remember Me
        </mat-checkbox>

        <!-- Login Button -->
        <button mat-raised-button
                id="login-submit-button"
                class="g-recaptcha btn-block"
                type="button"
                color="primary"
                [attr.data-sitekey]="googleRecaptchaSiteKey"
                data-callback="login"
                [disabled]="loginForm.invalid || (loginLoading | async)">
            Sign In
        </button>

        <!-- Links -->
        <div fxLayout="row" class="login-form-links" fxLayoutAlign="space-around center">
            <div>
                <a [href]="'/auth/forgot-password'">Forgot Password</a> |
                <a [href]="'/auth/resend-activation'">Resend activation email</a>
            </div>
        </div>
    </form>
</div>

