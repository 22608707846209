import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {AuthService} from '../../../services/auth.service';
import {SnackBarService} from '../../../services/snack-bar.service';
import {take} from 'rxjs/operators';

@Component({
    selector: 'auth-report-dialog',
    templateUrl: './auth-report-dialog.component.html',
    styleUrls: ['./auth-report-dialog.component.scss']
})
export class AuthReportDialogComponent implements OnInit, OnDestroy {
    loading = false;
    opportunities: any[];
    form: UntypedFormGroup;
    orgName: string;
    formType: 'question' | 'problem';

    private _unsubscribeAll: Subject<void>;
    constructor(
        public _dialogRef: MatDialogRef<AuthReportDialogComponent>,
        private _formBuilder: UntypedFormBuilder,
        private _authService: AuthService,
        private _snackBarService: SnackBarService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.formType = data && data.formType ? data.formType : 'problem';

        this.form = this._formBuilder.group({
            type: [this.formType, Validators.required],
            to: [{value: 'support@studentoc.com', disabled: true}],
            name: ['', Validators.required],
            email: ['', Validators.required],
            organization: ['', Validators.required],
            subject: ['', Validators.required],
            description: ['', Validators.required],
            url: [window.location.href],
        });

        this._unsubscribeAll = new Subject();
    }

    onNoClick(): void {
        this._dialogRef.close(false);
    }

    async ngOnInit(): Promise<void> {
        const currentUser = await this._authService.currentUser().pipe(take(1)).toPromise();
        if (currentUser) {
            const userDoc = await this._authService.getUserDoc();
            this.form.patchValue({
                name: userDoc['firstName'] + ' ' + userDoc['lastName'],
                email: userDoc['email'],
                organization: userDoc['organizationName']
            });
        }
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    async onSubmit(): Promise<any> {
        try {
            if (!this.form.valid) {
                return;
            }

            this.loading = true;

            const formData = this.form.getRawValue();

            const params = {
                type: formData['type'],
                firstName: formData['name'],
                lastName: '',
                email: formData['email'],
                subject: formData['subject'],
                description: formData['description'],
                org: formData['organization'],
                url: formData['url']
            };

            await this._authService.sendSupportEmail(params);

            this.loading = false;
            this.form.reset();
            this._snackBarService.open('Thank you for reporting the issue.');
            this._dialogRef.close(true);
        } catch (err) {
            this._snackBarService.open(err);
            this._dialogRef.close(false);
        }

    }

}
